import qs from 'qs'
import axiosInstance from 'src/axiosConfig'
import { AxiosResponse } from 'axios'

const baseUrlProjectInvitation = '/api/project/invite-users'

interface InvitationData {
  emails: string[]
}

const inviteUsersToProject = async (
  invitationData: InvitationData,
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrlProjectInvitation}/${project_id}`,
    qs.stringify(invitationData, { arrayFormat: 'repeat' })
  )

  return response
}

export default { inviteUsersToProject }
