import { Project } from 'src/lib/types'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../ui/card'
import i18n from 'src/i18n'
import { Link } from 'react-router-dom'
import { BsCircleFill } from 'react-icons/bs'
import { iconAndTextStyling } from 'src/constants'
import { Separator } from '../ui/separator'
import { isProjectActive } from 'src/lib/utils'

interface Props {
  project: Project
}

const ProjectCard = ({ project }: Props) => {
  const projectIsActive = isProjectActive(project)
  return (
    <Card className="h-full" data-testid={`project-card-${project.name}`}>
      <Link to={`${i18n.t('paths.projects')}/${project.id}`}>
        <CardHeader>
          <CardTitle>{project.name}</CardTitle>
          <CardDescription className={iconAndTextStyling}>
            {projectIsActive ? (
              <>
                <BsCircleFill className="text-green-400" />{' '}
                {i18n.t('activityStates.active')}
              </>
            ) : (
              <>
                <BsCircleFill className="text-red-400" />{' '}
                {i18n.t('activityStates.notActive')}
              </>
            )}
          </CardDescription>
          <Separator />
          <CardContent>{project.description}</CardContent>
        </CardHeader>
      </Link>
    </Card>
  )
}

export default ProjectCard
