import { AxiosResponse } from 'axios'
import axiosInstance from 'src/axiosConfig'

const baseUrl = '/api/blueprints'

interface BlueprintData {
  title: string
  file: File
  rotation: number
}

interface UpdatedBlueprintData {
  title: string
  rotation: number
}

const getSignedUrlByBlueprintId = async (
  blueprint_id: string,
  project_id: string
): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await axiosInstance.get(
      `${baseUrl}/${blueprint_id}/signed-url/projects/${project_id}`
    )

    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(`Unexpected response status: ${response.status}`)
    }
  } catch (error: any) {
    // Handle errors such as network issues or invalid status codes
    throw new Error(`Error fetching signed URL: ${error.message}`)
  }
}

const uploadBlueprint = async (
  blueprintData: BlueprintData,
  project_id: string
) => {
  const request = await axiosInstance.post(
    `${baseUrl}/add-new/projects/${project_id}`,
    blueprintData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

  return request.data
}

const getBlueprintsByProjectId = async (
  projectId: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(`${baseUrl}/projects/${projectId}`)
  return response
}

const deleteBlueprintById = async (blueprintId: string, project_id: string) => {
  const request = await axiosInstance.delete(
    `${baseUrl}/${blueprintId}/projects/${project_id}`
  )
  return request.data
}

const updateBlueprintById = async (
  blueprintId: string,
  updatedBlueprintData: UpdatedBlueprintData,
  project_id: string
) => {
  const request = await axiosInstance.put(
    `${baseUrl}/${blueprintId}/projects/${project_id}`,
    updatedBlueprintData
  )
  return request.data
}

const getBlueprintById = async (blueprintId: string, project_id: string) => {
  const request = await axiosInstance.get(
    `${baseUrl}/${blueprintId}/projects/${project_id}`
  )
  return request.data
}

export default {
  uploadBlueprint,
  getBlueprintsByProjectId,
  deleteBlueprintById,
  updateBlueprintById,
  getBlueprintById,
  getSignedUrlByBlueprintId,
}
