import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Button } from '../ui/button'
import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectContent,
  SelectValue,
} from '../ui/select'
import { fetchInvitationStatuses } from 'src/services/ProjectInvitations/invitationStatuses'
import { formatDateLocaleExact } from 'src/lib/utils'
import i18n from 'src/i18n'
import { iconAndTextStyling } from 'src/constants'
import { MailSearch } from 'lucide-react'
import { useAppContext } from 'src/context/AppProvider'

interface Props {
  projectId: string
}

interface InvitationStatus {
  email: string
  sent: string
  status: string
}

const InvitationStatusDialog = ({ projectId }: Props) => {
  const [statuses, setStatuses] = useState<InvitationStatus[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [statusFilter, setStatusFilter] = useState<string>('pending') // Track the selected status
  const { currentUser } = useAppContext()

  const loadInvitationStatuses = async () => {
    setLoading(true)
    setError('')

    const { invitations, error } = await fetchInvitationStatuses({
      project_id: projectId,
      status: statusFilter, // Pass the selected filter to the service
    })

    if (error) {
      setError(error)
    } else {
      setStatuses(invitations)
    }

    setLoading(false)
  }

  // Fetch invitation statuses when the dialog opens or filter changes
  useEffect(() => {
    if (isDialogOpen) {
      loadInvitationStatuses()
    }
  }, [isDialogOpen, statusFilter])

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger
        asChild
        data-testid="project-invitation-statuses-dialog-trigger"
      >
        <Button className={iconAndTextStyling} variant={'secondary'}>
          <MailSearch /> {i18n.t('invitation.buttons.checkStatuses')}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-xl p-4">
        <DialogHeader>
          <DialogTitle>{i18n.t('invitation.invitationStatuses')}</DialogTitle>
          {/* Dropdown in top-right position */}
          <Select
            value={statusFilter}
            onValueChange={(value: string) => setStatusFilter(value)}
          >
            <SelectTrigger className="absolute top-10 right-0 mt-2 mr-4 w-[140px] bg-black text-white rounded-md">
              <SelectValue>
                {i18n.t(`invitation.statuses.${statusFilter}`)}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="pending">
                {i18n.t('invitation.statuses.pending')}
              </SelectItem>
              <SelectItem value="accepted">
                {i18n.t('invitation.statuses.accepted')}
              </SelectItem>
              <SelectItem value="declined">
                {i18n.t('invitation.statuses.declined')}
              </SelectItem>
              <SelectItem value="all">
                {i18n.t('invitation.statuses.all')}
              </SelectItem>
            </SelectContent>
          </Select>
        </DialogHeader>
        <div className="text-sm mb-4 mt-12">
          {loading ? (
            <p>{i18n.t('invitation.loading')}</p>
          ) : error ? (
            <p className="text-red-600">{error}</p>
          ) : (
            <div className="overflow-x-auto max-h-96 overflow-y-auto">
              {' '}
              {/* Limited height and scrolling */}
              <table className="min-w-full border border-gray-200 shadow-sm rounded-lg">
                <thead className="bg-gray-100 text-gray-700">
                  <tr>
                    <th className="px-4 py-2 border-b">
                      {i18n.t('invitation.tableHeaders.email')}
                    </th>
                    <th className="px-4 py-2 border-b">
                      {i18n.t('invitation.tableHeaders.sent')}
                    </th>
                    <th className="px-4 py-2 border-b">
                      {i18n.t('invitation.tableHeaders.status')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {statuses.length > 0 ? (
                    statuses.map((status, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                        data-testid={`invitations-table-row-${status.email}`}
                      >
                        <td className="px-4 py-2 border-b">{status.email}</td>
                        <td className="px-4 py-2 border-b">
                          {formatDateLocaleExact(status.sent, currentUser)}
                        </td>
                        <td className="px-4 py-2 border-b capitalize">
                          {i18n.t(`invitation.statuses.${status.status}`)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3} className="px-4 py-4 text-center">
                        {i18n.t('invitation.placeHolders.noInvitationsFound')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <DialogFooter>
          <Button onClick={loadInvitationStatuses}>
            {i18n.t('invitation.buttons.refreshStatuses')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default InvitationStatusDialog
