import RegisterForm from 'src/components/Register/Register'

const RegisterView = () => {
  return (
    <div data-testid="register-view" className="mx-2 my-6">
      <RegisterForm />
    </div>
  )
}

export default RegisterView
