import CreateOrUpdateCustomershipForm from 'src/components/Forms/CreateOrUpdateCustomershipForm'

const CreateCustomershipView = () => {
  return (
    <div className="mx-2 my-4">
      <CreateOrUpdateCustomershipForm />
    </div>
  )
}

export default CreateCustomershipView
