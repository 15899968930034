import { useParams } from 'react-router'
import ChangePasswordForm from 'src/components/Forms/ChangePasswordForm'

const ChangePasswordView = () => {
  const { token } = useParams()

  if (!token) {
    return <div>No token found</div>
  }

  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <div className="w-full max-w-md">
        <ChangePasswordForm token={token} />
      </div>
    </div>
  )
}

export default ChangePasswordView
