import React, { Dispatch, SetStateAction } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import { ForeignUserData, UserProjectRoles } from 'src/lib/types'
import i18n from 'src/i18n'
import { Button } from '../ui/button'
import { toast } from 'react-toastify'
import projectsService from 'src/services/Projects/projects'
import { useParams } from 'react-router'
import {
  currentUserDataIsDefined,
  displayResponseErrorMessage,
  hasProjectAdminRights,
  hasProjectModeratorRights,
} from 'src/lib/utils'
import { useConfirmaDialogStableTarget } from 'src/components/ui/confirmDialog'
import { iconAndTextStyling } from 'src/constants'
import { Trash2 } from 'lucide-react'
import ManageUserButton from './ManageUserButton'
import SingleUserInfoDialog from './SingleUserInfoDialog'
import { useAppContext } from 'src/context/AppProvider'

interface Props {
  users: ForeignUserData[]
  setUsers: Dispatch<SetStateAction<ForeignUserData[]>>
}

const UsersTable = ({ users, setUsers }: Props) => {
  const { projectId } = useParams()
  const { currentUser } = useAppContext()
  const confirmDeletion = useConfirmaDialogStableTarget()

  const handleDeleteUserFromProject = async (
    userToDeleteId: string,
    projectId: string
  ) => {
    const confirmed = await confirmDeletion({
      title: i18n.t('confirmDelete'),
    })

    if (!confirmed) {
      return
    }
    try {
      const response = await projectsService.deleteUserFromProject(
        userToDeleteId,
        projectId
      )
      if (response.status === 200) {
        setUsers((oldUsers) => {
          return oldUsers.filter((u) => u.userData.id !== userToDeleteId)
        })
        toast.success(i18n.t('successGeneric'))
      }
    } catch (error: any) {
      // Extract and display the error message from the response
      displayResponseErrorMessage(error)
    }
  }

  if (!projectId || !currentUserDataIsDefined(currentUser)) {
    return <div>Loading project users</div>
  }

  const shouldRenderDeleteButtonToUserRow = (user: ForeignUserData) => {
    return (
      currentUser.userData.email !== user.userData.email &&
      hasProjectModeratorRights(currentUser) &&
      ((currentUser.project_role === UserProjectRoles.MODERATOR &&
        user.project_role === UserProjectRoles.MEMBER) ||
        hasProjectAdminRights(currentUser))
    )
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">{i18n.t('email')}</TableHead>
          <TableHead>{i18n.t('phone')}</TableHead>
          <TableHead>{i18n.t('projectRoles.role')}</TableHead>
          <TableHead>{i18n.t('manage')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {users.map((user) => (
          <TableRow
            key={user.userData.email}
            data-testid={`users-table-row-${user.userData.email}`}
          >
            <TableCell className="font-medium">
              <SingleUserInfoDialog email={user.userData.email} />
            </TableCell>
            <TableCell>{user.userData.phone}</TableCell>
            <TableCell>
              {user.project_role
                ? i18n.t(`projectRoles.${user.project_role.toLowerCase()}`)
                : '-'}
            </TableCell>
            <TableCell className="flex">
              {shouldRenderDeleteButtonToUserRow(user) && (
                <Button
                  data-testid={`delete-user-from-project-button-${user.userData.email}`}
                  className={iconAndTextStyling}
                  variant={'destructive'}
                  onClick={() =>
                    handleDeleteUserFromProject(user.userData.id, projectId)
                  }
                >
                  <Trash2 /> {i18n.t('delete')}
                </Button>
              )}
              <ManageUserButton user={user} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default UsersTable
