import React, { useState } from 'react'
import { toast } from 'react-toastify'
import '../../style/login.css'
import { Input } from '../ui/input'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../ui/button'
import i18n from 'src/i18n'
import { Label } from '../ui/label'
import loginService from 'src/services/Auth/login'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardTitle,
} from '../ui/card'
import { Separator } from '../ui/separator'
import { Field, Form, Formik } from 'formik'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import FormErrorMessage from '../Forms/FormErrorMessage'
import * as Yup from 'yup'

const stylesForText =
  'block uppercase tracking-wide text-black-700 text-sm font-bold'

interface LoginProps {
  onLoginSuccess: () => void
}

interface LoginFormValues {
  email: string
  password: string
}

const Login = ({ onLoginSuccess }: LoginProps) => {
  const [email, setEmail] = useState<string>('')
  const [showResendLink, setShowResendLink] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleLogin = async (values: LoginFormValues) => {
    try {
      const response = await loginService.loginUser({
        username: values.email,
        password: values.password,
      })
      const user = response.data
      if (response.status === 200 && user) {
        onLoginSuccess()
        i18n.changeLanguage(user.language)
        navigate(i18n.t('paths.mainscreen'))
      } else {
        if (response.status === 404) {
          toast.error(i18n.t('authMessages.loginFailed'))
        } else if (response.status === 401) {
          toast.warning(i18n.t('authMessages.emailNotVerified'))
          setShowResendLink(true)
          setEmail(values.email)
        }
      }
    } catch (error: any) {
      if (error.response?.status === 429) {
        toast.error(i18n.t('limiter.login'))
      } else {
        toast.error(i18n.t('errorGeneric'))
      }
    }
  }

  const handleResendVerification = async () => {
    if (!email || email.length === 0) {
      return
    }
    try {
      const response = await loginService.resendVerificationEmail(email)
      if (response.status === 200) {
        toast.success(i18n.t('authMessages.verificationEmailSent'))
      } else {
        toast.error(i18n.t('authMessages.verificationEmailFailed'))
      }
    } catch (error: any) {
      if (error.response?.status === 429) {
        toast.error(i18n.t('limiter.verifyEmail'))
      } else {
        toast.error(i18n.t('errorGeneric'))
      }
    }
  }

  return (
    <div className="login-container" data-testid="login-container">
      <h1 className="login-text sm:text-6xl text-2xl mb-8">
        {i18n.t('GOBOSOFT')}
      </h1>
      {showResendLink && (
        <div className="my-4">
          <Card
            className="w-full max-w-md"
            data-testid="login-view-resend-email-suggestion-card"
          >
            <CardTitle className="mb-4">
              {i18n.t('youNeedToConfirmEmail')}
            </CardTitle>
            <Separator className="my-3" />
            <CardDescription>{i18n.t('emailConfirmationSent')}</CardDescription>
            <Separator className="my-3" />
            <CardFooter className="flex flex-col gap-3 mt-2">
              <p>{i18n.t('questionDidntReceiveEmail')}</p>
              <p>{i18n.t('loginFormEmailResendInstructions')}</p>
              <p>
                {i18n.t('confirmationEmailWillBeSentTo')}: {email}
              </p>
              <Button
                disabled={!email}
                onClick={handleResendVerification}
                data-testid="resend-verification-link"
              >
                {i18n.t('sendNewConfirmationEmail')}
              </Button>
            </CardFooter>
          </Card>
        </div>
      )}
      <Card className="w-full max-w-md">
        <CardTitle>{i18n.t('logIn')}</CardTitle>
        <Separator className="my-3" />
        <CardContent>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email(i18n.t('emailValidation.invalidFormat'))
                .required(i18n.t('fieldValidation.required')),
              password: Yup.string().required(
                i18n.t('fieldValidation.required')
              ),
            })}
            onSubmit={handleLogin}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form className="flex flex-col gap-3" data-testid="login-form">
                <Label className={stylesForText}>{i18n.t('email')}</Label>
                <Field
                  type="email"
                  name="email"
                  placeholder={i18n.t('email')}
                  className="bg-white text-black rounded-lg px-3 py-2"
                  data-testid="email-input"
                  as={Input}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value)
                    setFieldValue('email', e.target.value)
                  }}
                />
                <FormErrorMessage name="email" />
                <Label className={stylesForText}>{i18n.t('password')}</Label>
                <Field
                  type="password"
                  name="password"
                  placeholder={i18n.t('password')}
                  className="bg-white text-black rounded-lg px-3 py-2"
                  data-testid="password-input"
                  as={Input}
                />
                <FormErrorMessage name="password" />
                <PendingSubmitButton
                  isSubmitting={isSubmitting}
                  buttonText={i18n.t('logIn')}
                  dataTestId="login-button"
                />
              </Form>
            )}
          </Formik>
        </CardContent>
        <Separator className="my-3" />
        <CardFooter className="flex flex-col gap-3 justify-start">
          <Link
            to={i18n.t('paths.register')}
            data-testid="redirect-to-register"
          >
            {i18n.t('dontHaveUserYet')}{' '}
            <span className="underline">{i18n.t('register')}</span>
          </Link>
          <Link
            to={i18n.t('paths.forgotpassword')}
            data-testid="forgot-password-link"
          >
            <span className="underline">{i18n.t('forgotPassword')}</span>
          </Link>
        </CardFooter>
      </Card>
    </div>
  )
}

export default Login
