import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import InvitationStatusDialog from 'src/components/Invitations/InvitationStatusDialog'
import InviteUserDialog from 'src/components/Invitations/InviteUserDialog'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import ModifyProjectUserRolesDialog from 'src/components/ModifyUserRoles/ModifyProjectUserRolesDialog'
import Pagination from 'src/components/Pagination'
import SearchUsers from 'src/components/Users/SearchUsers'
import UsersTable from 'src/components/Users/UsersTable'
import { Button } from 'src/components/ui/button'
import i18n from 'src/i18n'
import { ForeignUserData, UserProjectRoles } from 'src/lib/types'
import { displayResponseErrorMessage } from 'src/lib/utils'
import { useConfirmaDialogStableTarget } from 'src/components/ui/confirmDialog'
import projectsService from 'src/services/Projects/projects'
import usersService from 'src/services/Users/users'
import ErrorPage from '../ErrorPage/ErrorPage'
import { Separator } from 'src/components/ui/separator'
import { useAppContext } from 'src/context/AppProvider'
import { DEFAULT_SEARCH_RESULTS, iconAndTextStyling } from 'src/constants'
import { ArrowRightToLine } from 'lucide-react'

const ProjectParticipantsView = () => {
  const { projectId } = useParams()
  const [users, setUsers] = useState<ForeignUserData[]>([])
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [totalResults, setTotalResults] = useState<number>(0)
  const confirmDeletion = useConfirmaDialogStableTarget()

  const { currentUser } = useAppContext()

  const resultsPerPage = parseInt(
    searchParams.get('resultsPerPage') ?? DEFAULT_SEARCH_RESULTS.toString()
  )

  const currentUserRole = currentUser.project_role

  const handleDeleteCurrentUserFromProject = async (projectId: string) => {
    const confirmed = await confirmDeletion({
      title: i18n.t('confirmDelete'),
    })

    if (!confirmed) {
      return
    }
    try {
      const response =
        await projectsService.deleteCurrentUserFromProject(projectId)
      if (response.status === 200) {
        navigate('/')
        toast.success(i18n.t('successGeneric'))
      }
    } catch (error: any) {
      // Extract and display the error message from the response
      displayResponseErrorMessage(error)
    }
  }

  const fetchUsers = async () => {
    if (projectId) {
      try {
        setLoading(true)
        const page = searchParams.get('page')
        const searchQuery = searchParams.get('search') ?? ''
        const role = searchParams.get('role') ?? ''
        const response = await usersService.searchUsersByProjectId(projectId, {
          page: page ? parseInt(page) : 1,
          search: searchQuery,
          role: role !== 'null' ? (role as UserProjectRoles) : undefined,
          results_per_page: resultsPerPage,
        })
        if (response.status === 200) {
          setUsers(response.data.users)
          setTotalResults(response.data.totalResults)
        }
      } catch (error) {
        displayResponseErrorMessage(error)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [searchParams])

  if (!projectId) {
    return <ErrorPage />
  }

  return (
    <div className="mx-2">
      <div className="flex flex-row flex-wrap gap-2 justify-between">
        <h1>{i18n.t('projectParticipants')}</h1>
        <Button
          data-testid="delete-current-user-from-project"
          variant={'destructive'}
          onClick={() => handleDeleteCurrentUserFromProject(projectId)}
          className={iconAndTextStyling}
        >
          <ArrowRightToLine />
          {i18n.t('leaveProject')}
        </Button>
      </div>

      <Separator className="my-3" />

      {currentUserRole === UserProjectRoles.ADMIN && (
        <div className="flex flex-col flex-wrap gap-2 my-2 sm:flex-row">
          <InviteUserDialog projectId={projectId} />
          <InvitationStatusDialog projectId={projectId} />
          <ModifyProjectUserRolesDialog
            projectId={projectId}
            fetchUsers={fetchUsers}
          />
        </div>
      )}
      {currentUserRole === UserProjectRoles.MODERATOR && (
        <div className="flex flex-col flex-wrap gap-2 my-2 sm:flex-row">
          <InviteUserDialog projectId={projectId} />
          <InvitationStatusDialog projectId={projectId} />
        </div>
      )}

      <div className="my-3">
        <SearchUsers />
      </div>
      {loading ? (
        <SmallLoadingCircleOnly />
      ) : (
        <div>
          <p className="mb-3">
            {i18n.t('totalSearchResults')}: {totalResults}
          </p>
          <UsersTable users={users} setUsers={setUsers} />
        </div>
      )}
      <div className="text-center my-2">
        <Pagination totalPages={Math.ceil(totalResults / resultsPerPage)} />
      </div>
    </div>
  )
}

export default ProjectParticipantsView
