import React, { useEffect, useState } from 'react'
import BlueprintDisplay from 'src/components/Blueprints/BlueprintDisplay'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import { Blueprint } from 'src/lib/types'
import blueprintsService from 'src/services/Blueprints/blueprints'
import AddOrUpdateBlueprintDialog from 'src/components/Blueprints/AddOrUpdateBlueprintDialog'
import SearchItems from 'src/components/Items/SearchItems'
import { UserProjectRoles } from 'src/lib/types'
import { useAppContext } from 'src/context/AppProvider'

const SingleProjectView = () => {
  const [blueprints, setBlueprints] = useState<Blueprint[]>([])
  const [selectedBlueprint, setSelectedBlueprint] = useState<Blueprint | null>(
    null
  )
  const [loadingBlueprints, setLoadingBlueprints] = useState<boolean>(false)
  const { currentUser, currentProject } = useAppContext()

  const currentUserRole = currentUser.project_role

  useEffect(() => {
    const fetchBlueprints = async () => {
      try {
        setLoadingBlueprints(true)
        const response = await blueprintsService.getBlueprintsByProjectId(
          currentProject.id
        )
        if (response.status === 200) {
          const fetchedBlueprints = response.data
          setBlueprints(fetchedBlueprints)
          setSelectedBlueprint(fetchedBlueprints[0])
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoadingBlueprints(false)
      }
    }

    fetchBlueprints()
  }, [])

  const handleSelection = (value: string) => {
    const selected = blueprints.find((blueprint) => blueprint.id === value)
    setSelectedBlueprint(selected || null)
  }

  if (!currentUserRole) {
    return <div>Error: No user</div>
  }

  if (loadingBlueprints) {
    return <SmallLoadingCircleOnly />
  }

  return (
    <div
      className="md:h-[85vh] mx-2 flex flex-col"
      data-testid="single-project-main-view"
    >
      <div
        className="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-2 w-full mt-1"
        data-testid="single-project-main-info-container"
      >
        <div className="sm:col-span-1 flex flex-row gap-2 flex-wrap">
          <div>
            <h2>{currentProject.name}</h2>
          </div>
          {currentUser.project_role === UserProjectRoles.ADMIN &&
            blueprints.length < currentProject.max_blueprints && (
              <div>
                <AddOrUpdateBlueprintDialog
                  blueprint={null}
                  setBlueprints={setBlueprints}
                  setSelectedBlueprint={setSelectedBlueprint}
                />
              </div>
            )}
        </div>
        <div className="sm:col-span-1">
          <SearchItems />
        </div>
      </div>
      <BlueprintDisplay
        handleSelection={handleSelection}
        blueprints={blueprints}
        selectedBlueprint={selectedBlueprint}
        setBlueprints={setBlueprints}
        setSelectedBlueprint={setSelectedBlueprint}
      />
    </div>
  )
}

export default SingleProjectView
