import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Button } from '../ui/button'
import i18n from 'src/i18n'
import { Blueprint, Bubble, Item } from 'src/lib/types'
import { BsTools } from 'react-icons/bs'
import itemsService from 'src/services/Items/items'
import ItemsTable from '../Items/ItemsTable'
import { toast } from 'react-toastify'
import bubblesService from 'src/services/Bubbles/bubbles'
import AddOrUpdateBubbleDialog from '../Blueprints/AddOrUpdateBubbleDialog'
import AddOrUpdateItemDialog from './AddOrUpdateItemDialog'
import { iconAndTextStyling } from 'src/constants'
import { Trash2 } from 'lucide-react'
import { displayResponseErrorMessage } from 'src/lib/utils'
import { useConfirmDialogUnStableTarget } from 'src/components/ui/confirmDialog'
import { Separator } from '../ui/separator'
import SmallLoadingCircleOnly from '../Loading/SmallLoadingCircle'

interface Props {
  bubble: Bubble
  blueprint: Blueprint
  setBubbles?: Dispatch<SetStateAction<Bubble[]>>
  bubbles?: Bubble[]
  imageUrl?: string
}

const SingleBubbleDialog = ({
  bubble,
  blueprint,
  setBubbles,
  bubbles,
  imageUrl,
}: Props) => {
  const [items, setItems] = useState<Item[]>([])
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const { prompt, result, reset } = useConfirmDialogUnStableTarget()
  const [pendingDeletion, setPendingDeletion] = useState<null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (result) {
      bubbleDelete()
      reset()
    }
  }, [result, reset])

  const fetchItems = async () => {
    try {
      setLoading(true)
      const fetchedItems = await itemsService.getItemsByBubbleId(
        bubble.id,
        blueprint.project_id
      )
      if (fetchedItems) {
        setItems(fetchedItems)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    } finally {
      setLoading(false)
    }
  }

  const bubbleDelete = async () => {
    try {
      if (!setBubbles) {
        return
      }
      const deletedBubbleId = await bubblesService.deleteBubbleById(
        bubble.id,
        blueprint.project_id
      )
      if (deletedBubbleId) {
        setBubbles((oldBubbles) =>
          oldBubbles.filter((b) => b.id !== deletedBubbleId)
        )
        toast.success(i18n.t('successGeneric'))
        setDialogOpen(false)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleBubbleDelete = async () => {
    if (items.length > 0) {
      toast.error(i18n.t('bubbleContainsItems'))
      return
    }
    prompt({
      title: i18n.t('confirmDelete'),
    })
  }

  return (
    <Dialog
      onOpenChange={async (open) => {
        if (open) {
          setDialogOpen(true)
          await fetchItems()
        } else {
          setDialogOpen(false)
        }
      }}
    >
      <DialogTrigger
        asChild
        data-testid={`bubble-trigger-${bubble.title}`}
        data-testlocation={`bubble-trigger-location-${bubble.x}-${bubble.y}`}
      >
        <Button className="rounded-full" title={bubble.title}>
          <BsTools />
        </Button>
      </DialogTrigger>
      <DialogContent className="lg:max-w-6xl">
        <DialogHeader>
          <DialogTitle>{bubble.title}</DialogTitle>
        </DialogHeader>
        <Separator className="my-2" />

        <div className="flex flex-row flex-wrap gap-2 justify-between mb-2">
          <DialogTitle className="my-2">{i18n.t('bubbleItems')}</DialogTitle>
          <div>
            <AddOrUpdateItemDialog bubble={bubble} setItems={setItems} />
          </div>
        </div>
        {loading ? (
          <SmallLoadingCircleOnly />
        ) : items && items.length > 0 ? (
          <ItemsTable items={items} setItems={setItems} />
        ) : (
          <p>{i18n.t('noItemsFound')}</p>
        )}

        {setBubbles && (
          <DialogFooter className="flex flex-row gap-2 flex-wrap">
            <Separator className="my-2" />
            <AddOrUpdateBubbleDialog
              blueprint={blueprint}
              bubble={bubble}
              setSelectedBlueprintBubbles={setBubbles}
              bubbles={bubbles}
              imageUrl={imageUrl}
            />
            <Button
              variant={'destructive'}
              onClick={handleBubbleDelete}
              className={iconAndTextStyling}
              data-testid={`delete-bubble-${bubble.title}`}
            >
              <Trash2 /> {i18n.t('deleteBubble')}
            </Button>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default SingleBubbleDialog
