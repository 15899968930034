import { Plus } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ProjectCard from 'src/components/Projects/ProjectCard'
import { Button } from 'src/components/ui/button'
import { iconAndTextStyling } from 'src/constants'
import i18n from 'src/i18n'
import { Project } from 'src/lib/types'
import projectsService from 'src/services/Projects/projects'

const AdminPanelMainView = () => {
  const [projects, setProjects] = useState<Project[]>([])

  const fetchData = async () => {
    try {
      // Fetch projects
      const response = await projectsService.getAllProjects()
      if (response.status === 200) {
        setProjects(response.data)
      }
    } catch (error) {
      console.error('Error fetching projects or invitations:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="m-2" data-testid="adminpanel-main-view">
      <div className="flex gap-2 flex-row flex-wrap justify-between">
        <h1>Adminpanel</h1>
        <div className="flex flex-col gap-2">
          <Button data-testid="add-new-project-link-button">
            <Link
              to={i18n.t('paths.addNewProject')}
              className={iconAndTextStyling}
            >
              <Plus /> {i18n.t('addNewProject')}
            </Link>
          </Button>
          <Button data-testid="create-customership-link-button">
            <Link
              to={i18n.t('paths.createCustomership')}
              className={iconAndTextStyling}
            >
              <Plus /> {i18n.t('createCustomership')}
            </Link>
          </Button>
        </div>
      </div>
      <div className="bg-slate-300 rounded-md p-2 my-2">
        {projects?.length > 0 ? (
          projects.map((project) => (
            <div key={project.id} className="my-1">
              <ProjectCard project={project} />
            </div>
          ))
        ) : (
          <div>
            <p>{i18n.t('noProjectsFound')}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminPanelMainView
