import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from '../ui/dialog'
import { Button } from '../ui/button'
import i18n from 'src/i18n'
import { displayResponseErrorMessage } from 'src/lib/utils'
import projectsService from 'src/services/Projects/projects'
import { toast } from 'react-toastify'
import { Project } from 'src/lib/types'

interface Props {
  projectId: string
  setProject: Dispatch<SetStateAction<Project | null>>
}

const CancelProjectTerminationDialog = ({ projectId, setProject }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const handleProjectCancelTermination = async () => {
    try {
      const response =
        await projectsService.cancelProjectTerminationByProjectId(projectId)
      if (response.status === 200) {
        const project = response.data as Project
        setProject(project)
        toast.success(i18n.t('successGeneric'))
        setDialogOpen(false)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger
        asChild
        data-testid="cancel-project-termination-dialog-trigger"
      >
        <Button variant={'outline'}>
          {i18n.t('cancelProjectTermination.link')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>{i18n.t('cancelProjectTermination.title')}</DialogHeader>
        <DialogDescription>
          {i18n.t('cancelProjectTermination.description')}
        </DialogDescription>
        <DialogFooter>
          <Button
            onClick={handleProjectCancelTermination}
            data-testid="confirm-cancel-termination"
          >
            {i18n.t('cancelProjectTermination.confirmContinue')}
          </Button>
          <DialogClose asChild>
            <Button variant={'destructive'}>{i18n.t('back')}</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default CancelProjectTerminationDialog
