import { Home } from 'lucide-react'
import { Link } from 'react-router-dom'
import AddNewProjectForm from 'src/components/AddNewProject/AddNewProjectForm'
import i18n from 'src/i18n'

const AddNewProjectView = () => {
  return (
    <div className="mx-2 my-6" data-testid="add-new-project-view">
      <div>
        <div>
          <Link
            to={i18n.t('paths.mainscreen')}
            className="flex items-center gap-1"
          >
            <Home />
            {i18n.t('backToMainscreen')}
          </Link>
        </div>
        <div>
          <AddNewProjectForm />
        </div>
      </div>
    </div>
  )
}

export default AddNewProjectView
