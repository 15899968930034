import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '../ui/breadcrumb'
import { Link, useLocation } from 'react-router-dom'
import i18n from 'src/i18n'
import { useTranslation } from 'react-i18next'
import { Customership } from 'src/lib/types'

interface Props {
  customership: Customership
}

const BreadcrumbsSingleCustomership = ({ customership }: Props) => {
  const location = useLocation()
  const path = location.pathname
  const { t } = useTranslation()

  const customershipPortalMainViewPath = t('paths.customershipPortal')

  const manageCustomershipPath =
    t('paths.customershipPortal') +
    '/' +
    t('paths.ownCustomerships') +
    '/' +
    customership.id +
    '/' +
    t('paths.manageCustomership')
  const inviteUsersPath = manageCustomershipPath + '/' + t('paths.inviteUsers')
  const usersPath =
    manageCustomershipPath + '/' + t('paths.customershipParticipants')
  const modifyCustomershipPath =
    manageCustomershipPath + '/' + t('paths.modifyCustomership')

  return (
    <Breadcrumb className="hidden md:flex">
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            <Link to={customershipPortalMainViewPath}>
              {i18n.t('customershipPortal')}
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          {path === manageCustomershipPath ? (
            <BreadcrumbPage>
              {customership.company_name} ({customership.business_id})
            </BreadcrumbPage>
          ) : (
            <BreadcrumbLink asChild>
              <Link to={manageCustomershipPath}>
                {customership.company_name} ({customership.business_id})
              </Link>
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>
        {path === inviteUsersPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('invitation.inviteUsers')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path === usersPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('customershipParticipants')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}

        {path === modifyCustomershipPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('modifyCustomership')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  )
}

export default BreadcrumbsSingleCustomership
