import React from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '../ui/breadcrumb'
import { Link, useLocation } from 'react-router-dom'
import { Project } from 'src/lib/types'
import i18n from 'src/i18n'
import { useTranslation } from 'react-i18next'

interface Props {
  project: Project
}

const BreadcrumbsSingleProject = ({ project }: Props) => {
  const location = useLocation()
  const path = location.pathname
  const { t } = useTranslation()

  const projectMainViewPath = t('paths.projects') + '/' + project.id
  const projectInventarionViewPath =
    projectMainViewPath + '/' + t('paths.inventarion')
  const projectInformationViewPath =
    projectMainViewPath + '/' + t('paths.infosection')
  const projectParticipantsViewPath =
    projectMainViewPath + '/' + t('paths.projectParticipants')

  const projectSettingsViewPath =
    projectMainViewPath + '/' + t('paths.settings')

  const projectTerminationViewPath =
    projectSettingsViewPath + '/' + t('paths.endProject')

  const projectHistoryViewPath = projectMainViewPath + '/' + t('paths.history')

  return (
    <Breadcrumb className="hidden md:flex">
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            <Link to={i18n.t('paths.mainscreen')}>{i18n.t('mainscreen')}</Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          {path === projectMainViewPath ? (
            <BreadcrumbPage>{project.name}</BreadcrumbPage>
          ) : (
            <BreadcrumbLink asChild>
              <Link to={projectMainViewPath}>{project.name}</Link>
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>
        {path === projectMainViewPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('mainView')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path.includes(projectInventarionViewPath) && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('inventarion')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path === projectInformationViewPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('projectInformation')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path === projectParticipantsViewPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('projectParticipants')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path === projectSettingsViewPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('projectSettings')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path === projectHistoryViewPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('projectHistory')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path.includes(projectTerminationViewPath) && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbLink asChild>
              <Link to={projectSettingsViewPath}>{t('projectSettings')}</Link>
            </BreadcrumbLink>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('endProject')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  )
}

export default BreadcrumbsSingleProject
