import {
  Contact,
  History,
  Home,
  Image,
  Package,
  Settings,
  Users2,
} from 'lucide-react'
import { Link } from 'react-router-dom'
import i18n from 'src/i18n'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip'
import { UserProjectRoles } from 'src/lib/types'
import { useAppContext } from 'src/context/AppProvider'

interface Props {
  projectId: string
}

const tooltipStyling =
  'flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8'

const SidenavSingleProject = ({ projectId }: Props) => {
  const { currentUser } = useAppContext()

  return (
    <nav className="flex flex-col items-center gap-4 px-2 sm:py-5" tabIndex={1}>
      <TooltipProvider delayDuration={1}>
        <Tooltip>
          <TooltipTrigger asChild data-testid="project-sidenav-link-mainscreen">
            <Link to={i18n.t('paths.mainscreen')} className={tooltipStyling}>
              <Home className="h-5 w-5" />
              <span className="sr-only">{i18n.t('mainscreen')}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">{i18n.t('mainscreen')}</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild data-testid="project-sidenav-link-project">
            <Link
              to={i18n.t('paths.projects') + `/${projectId}`}
              className={tooltipStyling}
            >
              <Image className="h-5 w-5" />
              <span className="sr-only">{i18n.t('project')}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">{i18n.t('project')}</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger
            asChild
            data-testid="project-sidenav-link-inventarion"
          >
            <Link
              to={
                i18n.t('paths.projects') +
                `/${projectId}/` +
                i18n.t('paths.inventarion')
              }
              className={tooltipStyling}
            >
              <Package className="h-5 w-5" />
              <span className="sr-only">{i18n.t('inventarion')}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">{i18n.t('inventarion')}</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild data-testid="project-sidenav-link-history">
            <Link
              to={
                i18n.t('paths.projects') +
                `/${projectId}/` +
                i18n.t('paths.history')
              }
              className={tooltipStyling}
            >
              <History className="h-5 w-5" />
              <span className="sr-only"> {i18n.t('projectHistory')}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">
            {' '}
            {i18n.t('projectHistory')}
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger
            asChild
            data-testid="project-sidenav-link-infosection"
          >
            <Link
              to={
                i18n.t('paths.projects') +
                `/${projectId}/` +
                i18n.t('paths.infosection')
              }
              className={tooltipStyling}
            >
              <Contact className="h-5 w-5" />
              <span className="sr-only">{i18n.t('projectInformation')}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">
            {i18n.t('projectInformation')}
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger
            asChild
            data-testid="project-sidenav-link-participants"
          >
            <Link
              to={
                i18n.t('paths.projects') +
                `/${projectId}/` +
                i18n.t('paths.projectParticipants')
              }
              className={tooltipStyling}
            >
              <Users2 className="h-5 w-5" />
              <span className="sr-only">{i18n.t('projectParticipants')}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">
            {i18n.t('projectParticipants')}
          </TooltipContent>
        </Tooltip>
        {currentUser?.project_role === UserProjectRoles.ADMIN && (
          <Tooltip>
            <TooltipTrigger asChild data-testid="project-sidenav-link-settings">
              <Link
                to={
                  i18n.t('paths.projects') +
                  `/${projectId}/` +
                  i18n.t('paths.settings')
                }
                className={tooltipStyling}
              >
                <Settings className="h-5 w-5" />
                <span className="sr-only">{i18n.t('projectSettings')}</span>
              </Link>
            </TooltipTrigger>
            <TooltipContent side="right">
              {i18n.t('projectSettings')}
            </TooltipContent>
          </Tooltip>
        )}
      </TooltipProvider>
    </nav>
  )
}

export default SidenavSingleProject
