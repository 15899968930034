import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Input } from '../ui/input'
import { Label } from '../ui/label'
import { Textarea } from '../ui/textarea'
import i18n from 'src/i18n'
import projectsService from 'src/services/Projects/projects'
import { toast } from 'react-toastify'
import FormErrorMessage from '../Forms/FormErrorMessage'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import { Card, CardDescription, CardTitle } from '../ui/card'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { Customership } from 'src/lib/types'
import customershipService from 'src/services/Customerships/customershipService'
import { useNavigate } from 'react-router'
import { Separator } from '../ui/separator'
import { displayResponseErrorMessage } from 'src/lib/utils'

interface FormValues {
  title: string
  description: string
  customershipId: string
  price: number
  max_blueprints: number
}

const validationSchema = () =>
  Yup.object().shape({
    title: Yup.string()
      .required(i18n.t('fieldValidation.required'))
      .max(50, i18n.t('fieldValidation.maxCharacters', { maxLength: 50 })),
    description: Yup.string().max(
      500,
      i18n.t('fieldValidation.maxCharacters', { maxLength: 500 })
    ),
    customershipId: Yup.string()
      .required(i18n.t('fieldValidation.required'))
      .min(1, i18n.t('fieldValidation.required')),
    price: Yup.number().required(i18n.t('fieldValidation.required')),
    max_blueprints: Yup.number().required(i18n.t('fieldValidation.required')),
  })

const AddNewProjectForm = () => {
  const [selectableCustomerships, setSelectableCustomerships] = useState<
    Customership[]
  >([])

  const navigate = useNavigate()

  useEffect(() => {
    const fetchCustomerships = async () => {
      try {
        const response = await customershipService.getUsersCustomerships()
        if (response && response.status === 200) {
          const result = response.data as Customership[]
          setSelectableCustomerships(result)
        }
      } catch (error) {
        console.error('Error fetching customerships:', error)
      }
    }
    fetchCustomerships()
  }, [])

  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      const res = await projectsService.addNewProject({
        name: values.title,
        description: values.description,
        customership_id: values.customershipId,
        price: values.price,
        max_blueprints: values.max_blueprints,
      })

      if (res.status === 200 || res.status === 201) {
        // show success message
        toast.success(i18n.t('successGeneric'))
        actions.resetForm()
        navigate(i18n.t('paths.mainscreen'))
      }
    } catch (error: any) {
      if (error.response?.status === 429) {
        toast.error(i18n.t('limiter.addProject'))
      } else {
        displayResponseErrorMessage(error)
      }
    }
  }

  /*
  For now, this is not needed
  const fetchProjectDetails = async (customershipId: string) => {
    if (!customershipId || customershipId === '') {
      toast.error(i18n.t('errorFetchingProjectDetails'))
      return
    }
    try {
      const response =
        await projectDetailsService.getProjectDetailsByCustomershipId(
          customershipId
        )
      if (response.status === 200) {
        const details = response.data as ProjectDetails[]
        setSelectableProjectDetails(details)
      }
    } catch (error) {
      console.error(error)
    }
  }
    */

  return (
    <div className="flex flex-col justify-center items-center">
      <Card className="w-full max-w-md">
        <CardTitle className="mb-2">{i18n.t('addNewProject')}</CardTitle>
        <Separator />
        <CardDescription className="my-2">
          <>{i18n.t('addNewProjectDescription')}</>
          <br></br>
          <>{i18n.t('addNewProjectDescriptionSecond')}</>
        </CardDescription>
        <Formik
          initialValues={{
            title: '',
            description: '',
            customershipId: '',
            price: 0,
            max_blueprints: 0,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form
              data-testid="add-new-project-form"
              className="flex flex-col my-4 gap-3"
            >
              <Label htmlFor="title">{i18n.t('title')}</Label>
              <Field
                type="text"
                id="title"
                name="title"
                data-testid="title"
                placeholder={i18n.t('title')}
                as={Input}
              />
              <FormErrorMessage name="title" />

              <Label htmlFor="description">{i18n.t('description')}</Label>
              <Field
                id="description"
                name="description"
                data-testid="description"
                placeholder={i18n.t('description')}
                as={Textarea}
              />
              <FormErrorMessage name="description" />

              <Label>{i18n.t('selectCustomership')}</Label>
              <Select
                onValueChange={(value) =>
                  setFieldValue('customershipId', value)
                }
              >
                <SelectTrigger data-testid="select-customership-trigger">
                  <SelectValue placeholder={i18n.t('selectCustomership')} />
                </SelectTrigger>
                <SelectContent>
                  {selectableCustomerships.map((item) => (
                    <SelectItem
                      key={item.id}
                      value={item.id.toString()}
                      data-testid={`select-item-customership-${item.business_id}`}
                    >
                      {item.company_name} ({item.business_id})
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormErrorMessage name="customershipId" />

              <Label htmlFor="max_blueprints">{i18n.t('maxBlueprints')}</Label>
              <Field
                type="number"
                step={1}
                id="max_blueprints"
                name="max_blueprints"
                data-testid="max_blueprints"
                placeholder={i18n.t('maxBlueprints')}
                as={Input}
              />
              <FormErrorMessage name="max_blueprints" />

              <Label htmlFor="price">{i18n.t('pricePerMonth')}</Label>
              <Field
                type="number"
                step={0.01}
                id="price"
                name="price"
                data-testid="price"
                placeholder={i18n.t('pricePerMonth')}
                as={Input}
              />
              <FormErrorMessage name="price" />

              <PendingSubmitButton
                buttonText={i18n.t('addNewProject')}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  )
}

export default AddNewProjectForm
