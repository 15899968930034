import SendChangePasswordMailForm from 'src/components/Forms/SendChangePasswordMailForm'

const ForgotPasswordView = () => {
  return (
    <div className="mx-2 flex flex-col justify-center items-center my-2">
      <SendChangePasswordMailForm />
    </div>
  )
}

export default ForgotPasswordView
