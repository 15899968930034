// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
/*
.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 5px 0;
  padding: 10px;
  background-color: #f0f0f0;
}

input {
  margin-bottom: 10px;
  padding: 5px;
}

button {
  padding: 8px 15px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  cursor: pointer;
}
li:hover {
  background-color: #e0e0e0;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}
*/
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;CAqCC","sourcesContent":["/* App.css */\r\n/*\r\n.App {\r\n  text-align: center;\r\n  font-family: Arial, sans-serif;\r\n}\r\n\r\nul {\r\n  list-style: none;\r\n  padding: 0;\r\n}\r\n\r\nli {\r\n  margin: 5px 0;\r\n  padding: 10px;\r\n  background-color: #f0f0f0;\r\n}\r\n\r\ninput {\r\n  margin-bottom: 10px;\r\n  padding: 5px;\r\n}\r\n\r\nbutton {\r\n  padding: 8px 15px;\r\n  background-color: #4caf50;\r\n  color: #fff;\r\n  border: none;\r\n  cursor: pointer;\r\n}\r\nli:hover {\r\n  background-color: #e0e0e0;\r\n  transition: background-color 0.3s ease;\r\n}\r\n\r\nbutton:hover {\r\n  background-color: #45a049;\r\n}\r\n*/\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
