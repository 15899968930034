import { Input } from '../ui/input'
import i18n from 'src/i18n'
import { Button } from '../ui/button'
import { Search, Settings2 } from 'lucide-react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useState } from 'react'
import { Dialog, DialogClose, DialogContent, DialogTrigger } from '../ui/dialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { UserCustomershipRoles, UserProjectRoles } from 'src/lib/types'
import { DEFAULT_SEARCH_RESULTS } from 'src/constants'

const SearchUsers = () => {
  const [searchParams] = useSearchParams()
  const { projectId, customershipId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchQuery, setSearchQuery] = useState<string>(
    searchParams.get('search') || ''
  )
  const [role, setRole] = useState<string>(searchParams.get('role') || '')
  const [resultsPerPage, setResultsPerPage] = useState<string>(
    searchParams.get('resultsPerPage') || ''
  )

  const handleSearch = () => {
    searchParams.set('page', '1')
    if (searchQuery) {
      searchParams.set('search', searchQuery) // Set search query in URLSearchParams
    } else {
      searchParams.delete('search')
    }
    if (role) {
      searchParams.set('role', role)
    } else {
      searchParams.delete('role')
    }
    if (resultsPerPage) {
      searchParams.set('resultsPerPage', resultsPerPage)
    } else {
      searchParams.delete('resultsPerPage')
    }
    navigate({ pathname: location.pathname, search: searchParams.toString() })
  }

  const selectableRoles =
    projectId && !customershipId
      ? Object.values(UserProjectRoles)
      : !projectId && customershipId
        ? Object.values(UserCustomershipRoles)
        : undefined

  return (
    <div className="flex flex-row gap-2 flex-grow flex-wrap sm:flex-nowrap">
      <Input
        type="search"
        placeholder={i18n.t('searchForUser')}
        className="w-full rounded-lg"
        data-testid="search-users-input-searchbar"
        value={searchQuery} // Controlled input value
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <div>
        <Select
          onValueChange={(value) => setResultsPerPage(value)}
          value={resultsPerPage}
        >
          <SelectTrigger data-testid="select-search-results-per-page-trigger">
            <SelectValue placeholder={i18n.t('resultsPerPage')} />
          </SelectTrigger>
          <SelectContent>
            {Array.from([DEFAULT_SEARCH_RESULTS, 20, 30, 50]).map(
              (resultsAmount) => (
                <SelectItem
                  key={resultsAmount}
                  value={resultsAmount.toString()}
                  data-testid={`select-resultsperpage-item-${resultsAmount}`}
                >
                  {resultsAmount}
                </SelectItem>
              )
            )}
          </SelectContent>
        </Select>
      </div>
      <Dialog>
        <DialogTrigger asChild data-testid="search-dialog-trigger">
          <Button variant={'outline'}>
            <Settings2 />
          </Button>
        </DialogTrigger>
        <DialogContent>
          <div className="flex flex-col gap-2 py-4 my-2">
            <Input
              type="search"
              data-testid="search-items-input-dialog"
              placeholder={i18n.t('searchForUser')}
              className="w-full rounded-lg"
              value={searchQuery} // Controlled input value
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Select onValueChange={(value) => setRole(value)} value={role}>
              <SelectTrigger data-testid="select-search-role-trigger">
                <SelectValue placeholder={i18n.t('projectRoles.role')} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={'null'}>{i18n.t('noChoice')}</SelectItem>
                {selectableRoles?.map((role) => (
                  <SelectItem
                    key={role}
                    value={role}
                    data-testid={`select-role-item-${role}`}
                  >
                    {i18n.t(`projectRoles.${role}`)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <DialogClose asChild data-testid="search-save-button-dialog">
            <Button>{i18n.t('save')}</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button onClick={handleSearch} data-testid="search-button-dialog">
              <Search />
            </Button>
          </DialogClose>
        </DialogContent>
      </Dialog>
      <Button onClick={handleSearch} data-testid="search-button-searchbar">
        <Search />
      </Button>
    </div>
  )
}

export default SearchUsers
