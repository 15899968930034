import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import ModifyProjectDialog from 'src/components/Projects/ModifyProjectDialog'
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card'
import { useAppContext } from 'src/context/AppProvider'
import i18n from 'src/i18n'
import { Project, UserProjectRoles } from 'src/lib/types'
import projectsService from 'src/services/Projects/projects'

const ProjectInfoSectionView = () => {
  const { projectId } = useParams()
  const [project, setProject] = useState<Project | null>(null)

  const { currentUser } = useAppContext()

  const currentUserRole = currentUser.project_role

  useEffect(() => {
    const fetchProject = async () => {
      if (projectId) {
        try {
          const response = await projectsService.getProjectById(projectId)
          if (response.status === 200) {
            setProject(response.data)
          }
        } catch (error) {
          console.error(error)
        }
      }
    }

    fetchProject()
  }, [projectId])

  if (!project) {
    return <div>Error loading project</div>
  }

  return (
    <div className="mx-2">
      <div className="flex flex-row flex-wrap gap-2 justify-between">
        <h1>{project.name}</h1>
        {currentUserRole === UserProjectRoles.ADMIN && (
          <ModifyProjectDialog project={project} setProject={setProject} />
        )}
      </div>
      <Card className="my-2" data-testid="project-description-card">
        <CardHeader>
          <CardTitle>{i18n.t('description')}</CardTitle>
          <CardDescription className="whitespace-break-spaces">
            {project.description}
          </CardDescription>
        </CardHeader>
      </Card>
      <Card className="my-2" data-testid="project-info_section-card">
        <CardHeader>
          <CardTitle>{i18n.t('infosection')}</CardTitle>
          <CardDescription className="whitespace-break-spaces">
            {project.info_section ?? '-'}
          </CardDescription>
        </CardHeader>
      </Card>
    </div>
  )
}

export default ProjectInfoSectionView
