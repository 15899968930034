export type Project = {
  id: string
  name: string
  temporary_contract_end_date: Date
  project_end_date?: Date
  extra?: string
  description?: string
  info_section?: string
  price: number
  max_blueprints: number
  created_at: Date
}

export type ProjectDetails = {
  id: string
  customership_id: string
  max_blueprints: number
  created_at: Date
  updated_at: Date
}

export type Invitation = {
  project_name: string
  sent: string
  id: string
}

export type CustomershipInvitation = {
  company_name: string
  business_id: string
  sent: string
  id: string
}

export type Blueprint = {
  id: string
  file_key: string
  title: string
  project_id: string
  rotation: number
}

export type Bubble = {
  id: string
  blueprint_id: string
  x: number
  y: number
  title: string
}

export type User = {
  id: string
  email: string
  phone?: string
  organization?: string
  language: string
  created_at?: Date
  updated_at?: Date
}

export type Item = {
  id: string
  bubble_id: string
  name: string
  amount: number
  unit: string
  latest_editor_id?: string
  latest_editor_email?: string
  last_update_type: ItemLastUpdateType
  last_update_amount?: number
  updated_at: Date
  category?: string
}

export type DeletedItem = {
  id: string
  bubble_id?: string
  name: string
  amount: number
  unit: string
  deletor_user_id?: string
  deletor_user_email?: string
  updated_at: Date
  category?: string
}

export type WasteItem = {
  id: string
  name: string
  amount: number
  unit: string
  last_update_amount: number
  updated_at: Date
  project_id: string
  category?: string
  latest_editor_id?: string
  latest_editor_email?: string
}

export enum ItemLastUpdateType {
  CREATION = 'creation',
  INCREASE_AMOUNT = 'increase_amount',
  DECREASE_AMOUNT = 'decrease_amount',
  CHANGE_CATEGORY = 'change_category',
  CHANGE_UNIT = 'change_unit',
  ADD_WASTE = 'add_waste',
}

export type SearchItemUtils = {
  categories: string[]
  units: string[]
}

export type SearchItemResults = {
  items: Item[]
  totalResults: number
}

export type SearchWasteItemResults = {
  items: WasteItem[]
  totalResults: number
}

export type SearchOutOfStockItemResults = {
  items: DeletedItem[]
  totalResults: number
}

export type AllLocationsReponse = {
  bubbles: Bubble[]
  blueprints: Blueprint[]
}

export interface CurrentUserState {
  userData: {
    email: string
    phone?: string
    language: string
  }
  project_role?: UserProjectRoles
  customership_role?: UserCustomershipRoles
}

export enum UserProjectRoles {
  MEMBER = 'member',
  MODERATOR = 'moderator',
  ADMIN = 'admin',
}

export enum UserCustomershipRoles {
  MEMBER = 'member',
  ADMIN = 'admin',
}

export interface ForeignUserData {
  userData: {
    id: string
    email: string
    phone?: string
    organization?: string
  }
  project_role?: UserProjectRoles
  customership_role?: UserCustomershipRoles
}

export interface Customership {
  id: string
  company_name: string
  business_id: string
  einvoice_address: string
  operator_id: string
  postal_address: string
  email: string
  phone: string
  contact_person: string
  is_active: boolean
  reference_number?: string
  created_at?: Date
  updated_at?: Date
}

export interface InvitationStatus {
  email: string
  sent: string
  status: string
}

export interface InvitationStatusResponse {
  invitations: InvitationStatus[]
  error?: string
}

export interface InvitationResponse {
  success: boolean
  message: string
  one_or_more_email_failed: boolean
}

export enum ItemDisplayType {
  ITEMS = 'items',
  WASTE_ITEMS = 'waste_items',
  OUT_OF_STOCK_ITEMS = 'out_of_stock_items',
}

export interface ConfirmationDialogProps {
  title: string
  text?: string
  confirmButtonText?: string
  cancelButtonText?: string
  target?: HTMLElement | null
}
