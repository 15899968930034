import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Button } from '../ui/button'
import i18n from 'src/i18n'
import { Label } from '../ui/label'
import { Input } from '../ui/input'
import blueprintsService from 'src/services/Blueprints/blueprints'
import { toast } from 'react-toastify'
import { Blueprint } from 'src/lib/types'
import { Field, Form, Formik } from 'formik'
import { validationSchemaAddBlueprint } from 'src/lib/validationSchemas'
import FormErrorMessage from '../Forms/FormErrorMessage'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import { PDFDocument } from 'pdf-lib'
import { FilePenIcon, ImagePlus, RotateCcw, RotateCwIcon } from 'lucide-react'
import DisplaySingleBlueprintImage from './DisplaySingleBlueprintImage'
import { iconAndTextStyling } from 'src/constants'
import { displayResponseErrorMessage } from 'src/lib/utils'
import { useAppContext } from 'src/context/AppProvider'

const readFile = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)

    reader.readAsArrayBuffer(file)
  })
}

const getPageCount = async (file: any) => {
  const arrayBuffer = await readFile(file)

  const pdf = await PDFDocument.load(arrayBuffer as ArrayBuffer)

  return pdf.getPageCount()
}

export const isImageOrPdf = (file: File) => {
  return file.type.startsWith('image/') || file.type === 'application/pdf'
}

interface Props {
  blueprints?: Blueprint[]
  setBlueprints: Dispatch<SetStateAction<Blueprint[]>>
  setSelectedBlueprint: Dispatch<SetStateAction<Blueprint | null>>
  blueprint: Blueprint | null
  imageUrl?: string
}

enum RotationDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

const RotationButtons = ({
  handleRotate,
}: {
  handleRotate: (direction: RotationDirection) => void
}) => {
  return (
    <div className="flex flex-row gap-1 flex-wrap my-1">
      <Button
        data-testid="rotate-blueprint-left"
        type="button"
        onClick={() => handleRotate(RotationDirection.LEFT)}
      >
        <RotateCcw />
      </Button>
      <Button
        data-testid="rotate-blueprint-right"
        type="button"
        onClick={() => handleRotate(RotationDirection.RIGHT)}
      >
        <RotateCwIcon />
      </Button>
    </div>
  )
}

const AddOrUpdateBlueprintDialog = ({
  blueprints,
  blueprint,
  setBlueprints,
  setSelectedBlueprint,
  imageUrl,
}: Props) => {
  const [imagePreview, setImagePreview] = useState<string>()
  const [imageFile, setImageFile] = useState<File>()
  const [rotation, setRotation] = useState<number>(blueprint?.rotation || 0) // New state for rotation
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const { currentProject } = useAppContext()

  const projectId = currentProject.id

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (blueprint) {
      toast.error(i18n.t('blueprintImageCantBeChanged'))
      return
    }
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0]

        if (!isImageOrPdf(file)) {
          toast.error(i18n.t('allowedFileTypesText'))
          return
        }

        if (file.type === 'application/pdf') {
          // Check if PDF contains only one page
          const numPages = await getPageCount(file)
          if (numPages !== 1) {
            toast.error(i18n.t('onlyOneFileAllowed'))
            return
          }
          setImageFile(file)
          const previewUrl = URL.createObjectURL(file)
          setImagePreview(previewUrl)
        } else {
          setImageFile(file)
          const previewUrl = URL.createObjectURL(file)
          setImagePreview(previewUrl)
        }
      } else {
        throw new Error(i18n.t('errorGeneric'))
      }
    } catch (error) {
      toast.error(i18n.t('errorGeneric'))
    }
  }

  const handleSubmit = async (values: { title: string }, actions: any) => {
    try {
      if (!blueprint) {
        // add new blueprint
        if (blueprints && blueprints.length >= currentProject.max_blueprints) {
          toast.error(i18n.t('maxBlueprintsAlreadyAdded'))
          return
        }

        if (!imageFile) {
          toast.error(i18n.t('fileRequired'))
          return
        }

        if (!isImageOrPdf(imageFile)) {
          toast.error(i18n.t('allowedFileTypesText'))
          return
        }

        // check pdf files pages amount
        if (imageFile.type === 'application/pdf') {
          const numPages = await getPageCount(imageFile)
          if (numPages !== 1) {
            toast.error(i18n.t('onlyOneFileAllowed'))
            return
          }
        }
        const res = await blueprintsService.uploadBlueprint(
          {
            title: values.title,
            file: imageFile,
            rotation,
          },
          projectId
        )

        if (res) {
          toast.success(i18n.t('blueprintAddedSuccessfully'))
          setBlueprints((oldBlueprints) => oldBlueprints.concat(res))
          setSelectedBlueprint(res)
          setImageFile(undefined)
          setImagePreview('')
          actions.resetForm()
          setDialogOpen(false)
        } else {
          throw new Error(i18n.t('errorGeneric'))
        }
      } else {
        // update blueprint
        const updatedBlueprint = await blueprintsService.updateBlueprintById(
          blueprint.id,
          { title: values.title, rotation },
          projectId
        )
        if (updatedBlueprint) {
          toast.success(i18n.t('successGeneric'))
          setBlueprints((oldBlueprints) =>
            oldBlueprints.map((b) =>
              b.id === updatedBlueprint.id ? updatedBlueprint : b
            )
          )
          setSelectedBlueprint(updatedBlueprint)
          setDialogOpen(false)
        } else {
          throw new Error(i18n.t('errorGeneric'))
        }
      }
    } catch (error: any) {
      if (error.response?.status === 429) {
        toast.error(i18n.t('limiter.addBlueprint'))
      } else {
        displayResponseErrorMessage(error)
      }
    }
  }

  const handleRotate = (direction: RotationDirection) => {
    // Increment rotation by 90 degrees
    if (direction === RotationDirection.RIGHT) {
      if (rotation >= 270) {
        toast.info(i18n.t('rotationNotAllowed'))
        return
      }
      setRotation((prevRotation) => prevRotation + 90)
    } else {
      if (rotation <= -270) {
        toast.info(i18n.t('rotationNotAllowed'))
        return
      }
      setRotation((prevRotation) => prevRotation - 90)
    }
  }

  const titleText = blueprint
    ? i18n.t('modifyBlueprint')
    : i18n.t('addNewBlueprint')

  const handleOpen = (open: boolean) => {
    if (open) {
      setDialogOpen(true)
      setRotation(blueprint?.rotation || 0)
    } else {
      setDialogOpen(false)
      setImagePreview(undefined)
    }
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={(open) => handleOpen(open)}>
      <DialogTrigger
        asChild
        data-testid={
          blueprint
            ? `modify-blueprint-button-${blueprint.title}`
            : 'add-new-blueprint-button'
        }
      >
        <Button className={iconAndTextStyling} variant={'secondary'}>
          {blueprint ? <FilePenIcon /> : <ImagePlus />} {titleText}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-2xl">
        <DialogHeader>
          <DialogTitle>{titleText}</DialogTitle>
        </DialogHeader>
        <Formik
          initialValues={{ title: blueprint?.title || '' }}
          validationSchema={validationSchemaAddBlueprint}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col gap-3 py-4">
              {!blueprint ? (
                <div>
                  <Label>{i18n.t('selectBlueprintImage')}</Label>
                  <p>{i18n.t('allowedFileTypesText')}</p>
                  <p>{i18n.t('onlyOneFileAllowed')}</p>
                  <Input
                    type="file"
                    name="image"
                    onChange={handleImageChange}
                    required
                    accept="image/*,.pdf"
                  />
                  {imagePreview && imageFile?.type !== 'application/pdf' && (
                    <div className="my-4">
                      <DisplaySingleBlueprintImage imageSource={imagePreview} />
                      <RotationButtons handleRotate={handleRotate} />
                    </div>
                  )}
                </div>
              ) : (
                <div className="my-4">
                  <div>
                    <DisplaySingleBlueprintImage
                      blueprint={blueprint}
                      imageSource={imageUrl!}
                    />
                  </div>
                </div>
              )}
              <FormErrorMessage name="image" />
              <Label htmlFor="title">{i18n.t('title')}</Label>
              <Field
                type="text"
                id="title"
                name="title"
                placeholder={i18n.t('blueprintTitleExample')}
                as={Input}
              />
              <FormErrorMessage name="title" />

              <PendingSubmitButton
                buttonText={i18n.t('save')}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default AddOrUpdateBlueprintDialog
