import { useNavigate } from 'react-router'
import { Formik, Field, Form } from 'formik'
import { validationSchemaCustomership } from 'src/lib/validationSchemas'
import FormErrorMessage from './FormErrorMessage'
import { Input } from '../ui/input'
import i18n from 'src/i18n'
import { Label } from '../ui/label'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import { displayResponseErrorMessage } from 'src/lib/utils'
import customershipService from 'src/services/Customerships/customershipService'
import { toast } from 'react-toastify'
import { Card } from '../ui/card'
import { Customership } from 'src/lib/types'

interface FormValues {
  companyName: string
  businessId: string
  eInvoiceAddress: string
  operatorId: string
  postalAddress: string
  email: string
  phone: string
  contactPerson: string
  referenceNumber: string
}

interface Props {
  customership?: Customership
}

const CreateOrUpdateCustomershipForm = ({ customership }: Props) => {
  const { t } = i18n
  const navigate = useNavigate()

  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      if (customership) {
        // update existing customership
        const response = await customershipService.modifyCustomershipById(
          {
            ...values,
            postal_address: values.postalAddress,
            company_name: values.companyName,
            operator_id: values.operatorId,
            contact_person: values.contactPerson,
            einvoice_address: values.eInvoiceAddress,
            reference_number: values.referenceNumber,
          },
          customership.id
        )
        if (response.status === 200) {
          toast.success(i18n.t('successGeneric'))
        }
      } else {
        // create a new customership
        const response = await customershipService.createNewCustomership({
          ...values,
          postal_address: values.postalAddress,
          business_id: values.businessId,
          company_name: values.companyName,
          operator_id: values.operatorId,
          contact_person: values.contactPerson,
          einvoice_address: values.eInvoiceAddress,
          reference_number: values.referenceNumber,
        })
        if (response.status === 200 || response.status === 201) {
          toast.success(i18n.t('successGeneric'))
          actions.resetForm()
          navigate(t('paths.customershipPortal'))
        }
      }
    } catch (error: any) {
      console.error(error)
      if (error.response?.status === 429) {
        toast.error(i18n.t('limiter.addCustomership'))
      } else {
        displayResponseErrorMessage(error)
      }
    }
  }

  const initialValues: FormValues = {
    companyName: customership?.company_name ?? '',
    businessId: customership?.business_id ?? '',
    eInvoiceAddress: customership?.einvoice_address ?? '',
    operatorId: customership?.operator_id ?? '',
    postalAddress: customership?.postal_address ?? '',
    email: customership?.email ?? '',
    phone: customership?.phone ?? '',
    contactPerson: customership?.contact_person ?? '',
    referenceNumber: customership?.reference_number ?? '',
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <Card
        className="w-full max-w-md"
        data-testid="create-update-customership-form"
      >
        {customership ? (
          <h1>{t('modifyCustomership')}</h1>
        ) : (
          <h1>{t('createCustomership')}</h1>
        )}
        <Formik
          key={i18n.language} // Add key prop to force re-render on language change
          initialValues={initialValues}
          validationSchema={validationSchemaCustomership} // Call the function here
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => {
            return (
              <Form className="flex flex-col my-4 gap-3">
                <div>
                  <Label htmlFor="companyName">{t('companyName')}</Label>
                  <Field
                    id="companyName"
                    name="companyName"
                    placeholder={t('companyName')}
                    type="text"
                    data-testid="companyName"
                    as={Input}
                  />
                  <FormErrorMessage name="companyName" />
                </div>
                <div>
                  <Label htmlFor="businessId">{t('businessId')}</Label>
                  <Field
                    id="businessId"
                    name="businessId"
                    type="text"
                    placeholder={t('businessId')}
                    data-testid="businessId"
                    disabled={customership ? true : false}
                    as={Input}
                  />
                  <FormErrorMessage name="businessId" />
                </div>
                <div>
                  <Label htmlFor="postalAddress">{t('postalAddress')}</Label>
                  <Field
                    id="postalAddress"
                    name="postalAddress"
                    data-testid="postalAddress"
                    placeholder={t('postalAddress')}
                    type="text"
                    as={Input}
                  />
                  <FormErrorMessage name="postalAddress" />
                </div>
                <div>
                  <Label htmlFor="eInvoiceAddress">
                    {t('eInvoiceAddress')}
                  </Label>
                  <Field
                    id="eInvoiceAddress"
                    data-testid="eInvoiceAddress"
                    name="eInvoiceAddress"
                    type="text"
                    placeholder={t('eInvoiceAddress')}
                    as={Input}
                  />
                  <FormErrorMessage name="eInvoiceAddress" />
                </div>
                <div>
                  <Label htmlFor="operatorId">{t('operatorId')}</Label>
                  <Field
                    id="operatorId"
                    data-testid="operatorId"
                    name="operatorId"
                    placeholder={t('operatorId')}
                    type="text"
                    as={Input}
                  />
                  <FormErrorMessage name="operatorId" />
                </div>
                <div>
                  <Label htmlFor="referenceNumber">
                    {t('referenceNumber')}
                  </Label>
                  <Field
                    id="referenceNumber"
                    data-testid="referenceNumber"
                    name="referenceNumber"
                    type="text"
                    placeholder={t('referenceNumber')}
                    as={Input}
                  />
                  <FormErrorMessage name="referenceNumber" />
                </div>
                <div>
                  <Label htmlFor="email">{t('email')}</Label>
                  <Field
                    id="email"
                    data-testid="email"
                    name="email"
                    type="email"
                    as={Input}
                    placeholder={t('email')}
                  />
                  <FormErrorMessage name="email" />
                </div>
                <div>
                  <Label htmlFor="phone">{t('phone')}</Label>
                  <Field
                    id="phone"
                    data-testid="phone"
                    name="phone"
                    type="text"
                    as={Input}
                    placeholder={t('phone')}
                  />
                  <FormErrorMessage name="phone" />
                </div>
                <div>
                  <Label htmlFor="contactPerson">{t('contactPerson')}</Label>
                  <Field
                    id="contactPerson"
                    data-testid="contactPerson"
                    name="contactPerson"
                    placeholder={t('contactPerson')}
                    type="text"
                    as={Input}
                  />
                  <FormErrorMessage name="contactPerson" />
                </div>
                <PendingSubmitButton
                  buttonText={
                    customership ? i18n.t('save') : i18n.t('createCustomership')
                  }
                  isSubmitting={isSubmitting}
                />
              </Form>
            )
          }}
        </Formik>
      </Card>
    </div>
  )
}

export default CreateOrUpdateCustomershipForm
