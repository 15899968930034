import SmallLoadingCircleOnly from '../Loading/SmallLoadingCircle'
import { Button } from '../ui/button'

export default function PendingSubmitButton({
  buttonText,
  isSubmitting,
  dataTestId,
}: {
  buttonText: string
  isSubmitting: boolean
  dataTestId?: string
}) {
  return (
    <Button
      aria-disabled={isSubmitting}
      type="submit"
      data-testid={dataTestId ?? 'pending-submit-button'}
    >
      {isSubmitting ? (
        <SmallLoadingCircleOnly borderColor="border-white" />
      ) : (
        buttonText
      )}
    </Button>
  )
}
