import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { Ellipsis } from 'lucide-react'
import i18n from 'src/i18n'
import { Button } from '../ui/button'
import SingleUserInfoDialog from './SingleUserInfoDialog'
import { ForeignUserData } from 'src/lib/types'

interface Props {
  user: ForeignUserData
}

const ManageUserButton = ({ user }: Props) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant={'ghost'}>
          <Ellipsis />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>{i18n.t('manage')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <SingleUserInfoDialog email={user.userData.email} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default ManageUserButton
