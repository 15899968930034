import { Input } from '../ui/input'
import i18n from 'src/i18n'
import { Button } from '../ui/button'
import { Search, Settings2 } from 'lucide-react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useState } from 'react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { sortingMethods } from 'src/views/SingleProject/ProjectInventarionView'
import itemsService from 'src/services/Items/items'
import { DEFAULT_SEARCH_RESULTS } from 'src/constants'

interface Props {
  hideSortingMethods?: boolean
  displayResultsPerPageSelection?: boolean
}

const SearchItems = ({
  hideSortingMethods,
  displayResultsPerPageSelection,
}: Props) => {
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [searchQuery, setSearchQuery] = useState<string>(
    searchParams.get('search') || ''
  )
  const [category, setCategory] = useState<string>(
    searchParams.get('category') || ''
  )
  const [unit, setUnit] = useState<string>(searchParams.get('unit') || '')
  const [sort, setSort] = useState<string>(searchParams.get('sort') || '')
  const [resultsPerPage, setResultsPerPage] = useState<string>(
    searchParams.get('resultsPerPage') || ''
  )

  const [selectableCategories, setSelectableCategories] = useState<string[]>([])
  const [selectableUnits, setSelectableUnits] = useState<string[]>([])

  const fetchSearchUtils = async () => {
    if (projectId) {
      try {
        const searchUtils =
          await itemsService.getSearchUtilsByProjectId(projectId)
        setSelectableCategories(searchUtils.categories)
        setSelectableUnits(searchUtils.units)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleSearch = () => {
    searchParams.set('page', '1')
    if (searchQuery) {
      searchParams.set('search', searchQuery) // Set search query in URLSearchParams
    } else {
      searchParams.delete('search')
    }
    if (category) {
      searchParams.set('category', category)
    } else {
      searchParams.delete('category')
    }
    if (unit) {
      searchParams.set('unit', unit)
    } else {
      searchParams.delete('unit')
    }
    if (sort) {
      searchParams.set('sort', sort)
    } else {
      searchParams.delete('sort')
    }
    if (resultsPerPage) {
      searchParams.set('resultsPerPage', resultsPerPage)
    } else {
      searchParams.delete('resultsPerPage')
    }
    const currentLocation = location.pathname
    const destination =
      currentLocation.endsWith(i18n.t('paths.inventarion')) ||
      currentLocation.endsWith(i18n.t('paths.history'))
        ? currentLocation
        : currentLocation + '/' + i18n.t('paths.inventarion')
    navigate({ pathname: destination, search: searchParams.toString() })
  }

  return (
    <div className="flex flex-row gap-2 flex-grow flex-wrap sm:flex-nowrap">
      <div className="flex flex-row flex-nowrap gap-2 w-full">
        <Input
          type="search"
          placeholder={i18n.t('searchForItem')}
          className="w-full rounded-lg"
          data-testid="search-items-input-searchbar"
          value={searchQuery} // Controlled input value
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button onClick={handleSearch} data-testid="search-button-searchbar">
          <Search />
        </Button>
      </div>
      {displayResultsPerPageSelection && (
        <div>
          <Select
            onValueChange={(value) => setResultsPerPage(value)}
            value={resultsPerPage}
          >
            <SelectTrigger data-testid="select-search-results-per-page-trigger">
              <SelectValue placeholder={i18n.t('resultsPerPage')} />
            </SelectTrigger>
            <SelectContent>
              {Array.from([DEFAULT_SEARCH_RESULTS, 20, 30, 50]).map(
                (resultsAmount) => (
                  <SelectItem
                    key={resultsAmount}
                    value={resultsAmount.toString()}
                    data-testid={`select-resultsperpage-item-${resultsAmount}`}
                  >
                    {resultsAmount}
                  </SelectItem>
                )
              )}
            </SelectContent>
          </Select>
        </div>
      )}
      <Dialog
        onOpenChange={async (open) => {
          if (open) {
            await fetchSearchUtils()
          }
        }}
      >
        <DialogTrigger asChild data-testid="search-dialog-trigger">
          <Button variant={'outline'}>
            <Settings2 />
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{i18n.t('modifySearchParams')}</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-2 py-4 my-2">
            <Input
              type="search"
              data-testid="search-items-input-dialog"
              placeholder={i18n.t('searchForItem')}
              className="w-full rounded-lg"
              value={searchQuery} // Controlled input value
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Select
              onValueChange={(value) => setCategory(value)}
              value={category}
            >
              <SelectTrigger data-testid="select-search-category-trigger">
                <SelectValue placeholder={i18n.t('category')} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={'null'}>{i18n.t('noChoice')}</SelectItem>
                {selectableCategories.map((categ) => (
                  <SelectItem
                    key={categ}
                    value={categ}
                    data-testid={`select-category-item-${categ}`}
                  >
                    {categ}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select onValueChange={(value) => setUnit(value)} value={unit}>
              <SelectTrigger data-testid="select-search-unit-trigger">
                <SelectValue placeholder={i18n.t('unit')} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={'null'}>{i18n.t('noChoice')}</SelectItem>
                {selectableUnits.map((unit) => (
                  <SelectItem
                    key={unit}
                    value={unit}
                    data-testid={`select-unit-item-${unit}`}
                  >
                    {unit}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {!hideSortingMethods && (
              <Select onValueChange={(value) => setSort(value)} value={sort}>
                <SelectTrigger data-testid="select-search-sort-trigger">
                  <SelectValue placeholder={i18n.t('sort')} />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={'null'}>{i18n.t('noChoice')}</SelectItem>
                  {sortingMethods.map((method) => (
                    <SelectItem
                      key={method.method}
                      value={method.method}
                      data-testid={`select-sort-item-${method.method}`}
                    >
                      {i18n.t(`${method.name}`)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          </div>
          <DialogClose asChild data-testid="search-save-button-dialog">
            <Button>{i18n.t('save')}</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button onClick={handleSearch} data-testid="search-button-dialog">
              <Search />
            </Button>
          </DialogClose>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default SearchItems
