import { Outlet, useParams } from 'react-router'
import SidenavSheet from 'src/components/Projects/SidenavSheet'
import Sidenav from 'src/components/Projects/Sidenav'
import { currentUserDataWithProjectRoleIsDefined } from 'src/lib/utils'
import ErrorPage from 'src/views/ErrorPage/ErrorPage'
import { useEffect, useState } from 'react'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import BreadcrumbsSingleProject from 'src/components/Projects/BreadcrumbsSingleProject'
import { useAppContext } from 'src/context/AppProvider'
import { Project } from 'src/lib/types'
import i18n from 'src/i18n'

const ProjectLayout = () => {
  const { projectId } = useParams<{ projectId: string }>()
  const [loading, setLoading] = useState<boolean>(true)
  const [userDataValidated, setUserDataValidated] = useState<boolean | null>(
    null
  )

  const {
    currentProject,
    currentUser,
    initializeCurrentProject,
    setCurrentProject,
    initializeCurrentUser,
  } = useAppContext()

  useEffect(() => {
    const initialize = async () => {
      setLoading(true)
      setUserDataValidated(null) // Reset validation state on each load

      try {
        if (projectId) {
          // Initialize project and user sequentially
          await initializeCurrentProject(projectId)
          await initializeCurrentUser(projectId)
        } else {
          setCurrentProject({} as Project)
          setUserDataValidated(false)
        }
      } catch (error) {
        setUserDataValidated(false)
      } finally {
        setLoading(false)
      }
    }

    initialize()
  }, [projectId])

  useEffect(() => {
    if (currentUserDataWithProjectRoleIsDefined(currentUser)) {
      setUserDataValidated(true)
    } else if (
      currentUser.project_role === null ||
      currentUser.project_role === undefined
    ) {
      // If project role is null or undefined, mark validation as failed
      setUserDataValidated(false)
    }
  }, [currentUser])

  if (loading) {
    return <SmallLoadingCircleOnly />
  }

  if (userDataValidated === false) {
    return <ErrorPage />
  }

  const projectMainViewPath = i18n.t('paths.projects') + '/' + currentProject.id

  return (
    <div className="project-layout">
      <aside className="fixed left-0 z-10 hidden w-14 flex-col border-r sm:flex bg-slate-300 rounded-md">
        {projectId && <Sidenav projectId={projectId} />}
      </aside>
      <div className="flex flex-col sm:gap-1 sm:py-1 sm:pl-14">
        <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b px-4 sm:static sm:h-auto sm:border-0 bg-white sm:px-6">
          {projectId && <SidenavSheet projectId={projectId} />}
        </header>
        {window.location.pathname !== projectMainViewPath && (
          <div className="m-2">
            <BreadcrumbsSingleProject project={currentProject} />
          </div>
        )}
        <Outlet />
      </div>
    </div>
  )
}

export default ProjectLayout
