import axiosInstance from 'src/axiosConfig'
import { AxiosResponse } from 'axios'

interface PromoteMembersData {
  members: { email: string; role: string }[]
}

const baseUrlProjects = '/api/projects'
const baseUrlCustomerships = '/api/customerships'

const modifyProjectUserRole = async (
  data: PromoteMembersData,
  projectId: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.put(
    `${baseUrlProjects}/${projectId}/modify-user-role`,
    data
  )
  return response
}

const modifyCustomershipUserRole = async (
  data: PromoteMembersData,
  customershipId: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.put(
    `${baseUrlCustomerships}/${customershipId}/modify-user-role`,
    data
  )
  return response
}

export default { modifyProjectUserRole, modifyCustomershipUserRole }
