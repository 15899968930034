import i18n from 'src/i18n'
import * as Yup from 'yup'

export const passwordValidationYup = () =>
  Yup.string()
    .min(8, () => i18n.t('passwordValidation.passwordLength'))
    .matches(/[a-z]/, () => i18n.t('passwordValidation.lowercaseLetter'))
    .matches(/[A-Z]/, () => i18n.t('passwordValidation.uppercaseLetter'))
    .matches(/[0-9]/, () => i18n.t('passwordValidation.digit'))
    .matches(/[!@#$%^&*]/, () => i18n.t('passwordValidation.specialCharacter'))
    .required(() => i18n.t('fieldValidation.required'))

export const passwordVerifyValidationYup = () =>
  Yup.string()
    .oneOf([Yup.ref('password'), undefined], () => i18n.t('passwordsDontMatch'))
    .required(() => i18n.t('fieldValidation.required'))

export const phoneValidationYup = () =>
  Yup.string().matches(
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
    i18n.t('phoneValidation.invalidFormat')
  )

const itemSchema = {
  name: Yup.string()
    .required(() => i18n.t('fieldValidation.required'))
    .max(100, () =>
      i18n.t('fieldValidation.maxCharacters', { maxLength: 100 })
    ),
  amount: Yup.number()
    .min(0, () => i18n.t('fieldValidation.greaterThan', { amount: 0 }))
    .required(() => i18n.t('fieldValidation.required')),
  unit: Yup.string()
    .required(() => i18n.t('fieldValidation.required'))
    .max(50, () => i18n.t('fieldValidation.maxCharacters', { maxLength: 50 })),
  category: Yup.string().max(120, () =>
    i18n.t('fieldValidation.maxCharacters', { maxLength: 120 })
  ),
}

export const validationSchemaUpdateItem = () => Yup.object().shape(itemSchema)

export const validationSchemaAddItem = () =>
  Yup.object().shape({
    ...itemSchema,
    amount: Yup.number()
      .moreThan(0, () => i18n.t('fieldValidation.greaterThan', { amount: 0 }))
      .required(() => i18n.t('fieldValidation.required')),
  })

export const validationSchemaAddOrUpdateBubble = () =>
  Yup.object().shape({
    title: Yup.string()
      .required(i18n.t('fieldValidation.required'))
      .max(50, () =>
        i18n.t('fieldValidation.maxCharacters', { maxLength: 50 })
      ),
  })

export const validationSchemaAddWasteItem = (maxWaste: number) =>
  Yup.object().shape({
    waste_amount: Yup.number()
      .moreThan(0, () => i18n.t('fieldValidation.greaterThan', { amount: 0 }))
      .max(maxWaste, () =>
        i18n.t('fieldValidation.maxNumber', { maxNumber: maxWaste })
      )
      .required(() => i18n.t('fieldValidation.required')),
  })

export const validationSchemaMoveItem = (maxMove: number) =>
  Yup.object().shape({
    move_amount: Yup.number()
      .moreThan(0, () => i18n.t('fieldValidation.greaterThan', { amount: 0 }))
      .max(maxMove, () =>
        i18n.t('fieldValidation.maxNumber', { maxNumber: maxMove })
      )
      .required(() => i18n.t('fieldValidation.required')),
  })

const isFile = (value: any): value is File => {
  return value instanceof File
}

export const validationSchemaAddBlueprint = () =>
  Yup.object().shape({
    title: Yup.string()
      .required(i18n.t('fieldValidation.required'))
      .max(200, i18n.t('fieldValidation.maxCharacters', { maxLength: 200 })),
  })

export const validationSchemaRegister = () =>
  Yup.object().shape({
    email: Yup.string()
      .email(i18n.t('emailValidation.invalidFormat'))
      .required(i18n.t('fieldValidation.required')),
    password: passwordValidationYup(),
    verifyPassword: passwordVerifyValidationYup(),
    language: Yup.string().required(i18n.t('fieldValidation.required')),
    phone: phoneValidationYup().notRequired(),
    termsAccepted: Yup.boolean().oneOf(
      [true],
      i18n.t('fieldValidation.required')
    ),
  })

export const validationSchemaCustomership = () =>
  Yup.object().shape({
    companyName: Yup.string().required(i18n.t('fieldValidation.required')),
    businessId: Yup.string().required(i18n.t('fieldValidation.required')),
    eInvoiceAddress: Yup.string().required(i18n.t('fieldValidation.required')),
    operatorId: Yup.string().required(i18n.t('fieldValidation.required')),
    postalAddress: Yup.string().required(i18n.t('fieldValidation.required')),
    email: Yup.string()
      .email(i18n.t('emailValidation.invalidFormat'))
      .required(i18n.t('fieldValidation.required')),
    phone: phoneValidationYup().notRequired(),
    contactPerson: Yup.string().required(i18n.t('fieldValidation.required')),
    referenceNumber: Yup.string(),
  })
