import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Button } from 'src/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card'
import i18n from 'src/i18n'
import {
  allowProjectEnd,
  formatDateLocale,
  isProjectActive,
} from 'src/lib/utils'
import projectsService from 'src/services/Projects/projects'
import ErrorPage from '../ErrorPage/ErrorPage'
import { Project, UserProjectRoles } from 'src/lib/types'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import CancelProjectTerminationDialog from 'src/components/Projects/CancelProjectTerminationDialog'
import { Link } from 'react-router-dom'
import { useAppContext } from 'src/context/AppProvider'

const ProjectSettingsView = () => {
  const { projectId } = useParams()
  const [project, setProject] = useState<Project | null>(null)
  const { currentUser, currentProject } = useAppContext()

  useEffect(() => {
    const fetchProject = async () => {
      if (projectId) {
        try {
          const response = await projectsService.getProjectById(projectId)
          if (response.status === 200) {
            setProject(response.data)
          }
        } catch (error) {
          console.error(error)
        }
      }
    }

    fetchProject()
  }, [])

  if (!project) {
    return <SmallLoadingCircleOnly />
  }
  if (!currentProject) {
    return <ErrorPage />
  }

  const projectIsActive = isProjectActive(project)

  return (
    <div className="mx-2">
      <h1>{i18n.t('projectSettings')}</h1>
      {projectIsActive &&
        currentUser.project_role === UserProjectRoles.ADMIN && (
          <div className="flex flex-col gap-2 my-4">
            <Card>
              <CardHeader>
                <CardTitle>{i18n.t('projectContractInfo')}</CardTitle>
                <CardDescription>
                  {i18n.t('projectCreated')}:{' '}
                  {formatDateLocale(project.created_at)}
                </CardDescription>
                <CardDescription>
                  {i18n.t('projectTemporaryContractEndingDate')}:{' '}
                  {formatDateLocale(project.temporary_contract_end_date)}
                </CardDescription>
                <CardDescription>
                  {i18n.t('maxBlueprints')}: {project.max_blueprints}
                </CardDescription>
                <CardDescription>
                  {i18n.t('pricePerMonth')}: {project.price}
                </CardDescription>
              </CardHeader>
              {/**Projekti peruttu mutta vielä aktiivinen => Ehdotetaan perumisen perumista */}
              {project.project_end_date && (
                <CardContent>
                  <p data-testid="project-end-date">
                    {i18n.t('projectEndDate')}:{' '}
                    {formatDateLocale(project.project_end_date)}
                  </p>
                  <p className="my-2">
                    {i18n.t('cancelProjectTermination.title')}
                  </p>
                  <CancelProjectTerminationDialog
                    projectId={project.id}
                    setProject={setProject}
                  />
                </CardContent>
              )}
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>
                  {project.project_end_date
                    ? i18n.t('projectHasBeenTerminated')
                    : i18n.t('endProject')}
                </CardTitle>
                <CardDescription>
                  {i18n.t('projectEndingDescription')}
                </CardDescription>
                <CardDescription>
                  {i18n.t('projectEndingContractInfo')}
                </CardDescription>
              </CardHeader>
              {allowProjectEnd(project) === true && (
                <CardContent>
                  <Link
                    to={
                      i18n.t('paths.projects') +
                      '/' +
                      project.id +
                      '/' +
                      i18n.t('paths.settings') +
                      '/' +
                      i18n.t('paths.endProject')
                    }
                  >
                    <Button
                      className="p-0 m-0"
                      variant={'link'}
                      data-testid="navigate-to-end-project-view"
                    >
                      {project.project_end_date
                        ? i18n.t('changeProjectEndDate')
                        : i18n.t('moveToEndProject')}
                    </Button>
                  </Link>
                </CardContent>
              )}
            </Card>
          </div>
        )}
      {!projectIsActive && project.project_end_date && (
        <Card>
          <CardHeader>
            <CardTitle>{i18n.t('projectHasEnded')}</CardTitle>
            <CardDescription>
              {i18n.t('projectEndedTime')}:{' '}
              {formatDateLocale(project.project_end_date)}
            </CardDescription>
            <CardDescription>{i18n.t('projectEndedDesc')}</CardDescription>
          </CardHeader>
        </Card>
      )}
    </div>
  )
}

export default ProjectSettingsView
