import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import InviteUserToCustomershipForm from 'src/components/CustomershipInvitations/InviteUserToCustomershipForm'
import { useAppContext } from 'src/context/AppProvider'
import { Customership } from 'src/lib/types'
import { currentUserDataIsDefined } from 'src/lib/utils'
import customershipService from 'src/services/Customerships/customershipService'

const InviteUsersToCustomershipView = () => {
  const { customershipId } = useParams()

  const { currentUser } = useAppContext()

  const [customership, setCustomership] = useState<Customership>()

  useEffect(() => {
    const fetchCustomership = async () => {
      try {
        if (customershipId) {
          const responseCustomership =
            await customershipService.getCustomershipById(customershipId)
          if (responseCustomership.status === 200) {
            const resultCustomership = responseCustomership.data as Customership
            setCustomership(resultCustomership)
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchCustomership()
  }, [])

  if (
    !customershipId ||
    !customership ||
    !currentUserDataIsDefined(currentUser)
  ) {
    return <div>No customership or current user found</div>
  }

  return (
    <div className="mx-2 my-4">
      <InviteUserToCustomershipForm customershipId={customership.id} />
    </div>
  )
}

export default InviteUsersToCustomershipView
