import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '../ui/breadcrumb'
import { Link, useLocation } from 'react-router-dom'
import i18n from 'src/i18n'
import { useTranslation } from 'react-i18next'

const BreadcrumbsCustomershipPortal = () => {
  const location = useLocation()
  const path = location.pathname
  const { t } = useTranslation()

  const customershipPortalMainViewPath = t('paths.customershipPortal')

  const createNewCustomershipPath =
    customershipPortalMainViewPath + '/' + t('paths.createCustomership')

  if (
    path !== customershipPortalMainViewPath &&
    path !== createNewCustomershipPath
  ) {
    return null
  }

  return (
    <Breadcrumb className="hidden md:flex">
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbPage>{i18n.t('customershipPortal')}</BreadcrumbPage>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            <Link to={customershipPortalMainViewPath}>
              {i18n.t('mainView')}
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        {path === createNewCustomershipPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('createCustomership')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  )
}

export default BreadcrumbsCustomershipPortal
