import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import ItemsTable from 'src/components/Items/ItemsTable'
import SearchItems from 'src/components/Items/SearchItems'
import ShowAllLocationsDialog from 'src/components/Items/ShowAllLocationsDialog'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import Pagination from 'src/components/Pagination'
import { DEFAULT_SEARCH_RESULTS } from 'src/constants'
import i18n from 'src/i18n'
import { Item } from 'src/lib/types'
import { displayResponseErrorMessage } from 'src/lib/utils'
import itemsService from 'src/services/Items/items'

interface SortingMethod {
  method: string
  name: string
}

export const sortingMethods: SortingMethod[] = [
  { method: 'unit_asc', name: 'unitAsc' },
  { method: 'unit_desc', name: 'unitDesc' },
  { method: 'updated_at_asc', name: 'updatedAtAsc' },
  { method: 'updated_at_desc', name: 'updatedAtDesc' },
  { method: 'amount_asc', name: 'amountAsc' },
  { method: 'amount_desc', name: 'amountDesc' },
] as SortingMethod[]

const ProjectInventarionView = () => {
  const [searchParams] = useSearchParams()
  const { projectId } = useParams()
  const [inventarion, setInventarion] = useState<Item[]>([])
  const [totalResults, setTotalResults] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const resultsPerPage = parseInt(
    searchParams.get('resultsPerPage') ?? DEFAULT_SEARCH_RESULTS.toString()
  )

  const fetchProject = async () => {
    if (projectId) {
      try {
        setLoading(true)
        const page = searchParams.get('page')
        const searchQuery = searchParams.get('search') ?? ''
        const category = searchParams.get('category') ?? ''
        const unit = searchParams.get('unit') ?? ''
        const sort = searchParams.get('sort') ?? ''
        console.log('Results per page: ', resultsPerPage)
        const fetchedInventarion = await itemsService.searchItemsByProjectId(
          projectId,
          {
            page: page ? parseInt(page) : 1,
            search: searchQuery,
            category: category !== 'null' ? category : '',
            unit: unit !== 'null' ? unit : '',
            sort: sort !== 'null' ? sort : '',
            items_per_page: resultsPerPage,
          }
        )
        if (fetchedInventarion) {
          setInventarion(fetchedInventarion.items)
          setTotalResults(fetchedInventarion.totalResults)
        }
      } catch (error) {
        displayResponseErrorMessage(error)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchProject()
  }, [searchParams])

  if (!projectId) {
    return <div>{i18n.t('errorGeneric')}</div>
  }

  return (
    <div className="mx-2" data-testid="single-project-inventarion-view">
      <h1 className="mb-2">{i18n.t('inventarion')}</h1>
      <SearchItems displayResultsPerPageSelection />
      <div className="flex flex-row gap-2 justify-between flex-wrap my-3">
        <div>
          {i18n.t('totalSearchResults')}: {totalResults}
        </div>
        <div className="my-2">
          <ShowAllLocationsDialog projectId={projectId} />
        </div>
      </div>
      {loading ? (
        <SmallLoadingCircleOnly />
      ) : (
        <div>
          <ItemsTable
            items={inventarion}
            setItems={setInventarion}
            showMapButton
          />
        </div>
      )}
      <div className="text-center my-2">
        <Pagination totalPages={Math.ceil(totalResults / resultsPerPage)} />
      </div>
    </div>
  )
}

export default ProjectInventarionView
