import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog'
import { Button } from '../ui/button'
import { MapIcon } from 'lucide-react'
import { AllLocationsReponse, Blueprint, Bubble } from 'src/lib/types'
import { toast } from 'react-toastify'
import i18n from 'src/i18n'
import DisplaySingleBlueprintImage from '../Blueprints/DisplaySingleBlueprintImage'
import blueprintsService from 'src/services/Blueprints/blueprints'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { useSearchParams } from 'react-router-dom'
import itemsService from 'src/services/Items/items'

interface Props {
  projectId: string
}

const ShowAllLocationsDialog = ({ projectId }: Props) => {
  const [bubbles, setBubbles] = useState<Bubble[]>([])
  const [blueprints, setBlueprints] = useState<Blueprint[]>()
  const [imageUrl, setImageUrl] = useState<string>('')
  const [selectedBlueprint, setSelectedBlueprint] = useState<Blueprint | null>(
    null
  )

  const [searchParams] = useSearchParams()
  const search = searchParams.get('search') ?? ''
  const category = searchParams.get('category') ?? ''
  const unit = searchParams.get('unit') ?? ''

  const fetchData = async () => {
    try {
      const response = await itemsService.getAllLocationsData(
        {
          search,
          category: category !== 'null' ? category : '',
          unit: unit !== 'null' ? unit : '',
        },
        projectId
      )
      if (response.status === 200) {
        const searchData = response.data as AllLocationsReponse
        setBlueprints(searchData.blueprints)
        setSelectedBlueprint(searchData.blueprints[0])
        setBubbles(searchData.bubbles)
      } else {
        toast.error(i18n.t('errorGeneric'))
      }
    } catch (error) {
      toast.error(i18n.t('errorGeneric'))
    }
  }

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (selectedBlueprint) {
        try {
          const signedUrl = await blueprintsService.getSignedUrlByBlueprintId(
            selectedBlueprint.id,
            selectedBlueprint.project_id
          )
          if (signedUrl) {
            setImageUrl(signedUrl)
          }
        } catch (error) {
          console.error(error)
        }
      }
    }

    fetchImageUrl()
  }, [selectedBlueprint])

  return (
    <Dialog
      onOpenChange={async (open) => {
        if (open) {
          await fetchData()
        }
      }}
    >
      <DialogTrigger asChild>
        <Button>
          {i18n.t('showAllLocations')} <MapIcon />
        </Button>
      </DialogTrigger>
      <DialogContent>
        {blueprints && (
          <div className="my-2">
            <Select
              onValueChange={(value) =>
                setSelectedBlueprint(
                  blueprints.find((b) => b.id === value) || null
                )
              }
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue
                  placeholder={selectedBlueprint?.title ?? i18n.t('blueprint')}
                />
              </SelectTrigger>
              <SelectContent>
                {blueprints.map((blueprint) => (
                  <SelectItem
                    key={blueprint.id}
                    value={blueprint.id.toString()}
                  >
                    {blueprint.title}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
        {selectedBlueprint && (
          <div>
            <DisplaySingleBlueprintImage
              blueprint={selectedBlueprint}
              imageSource={imageUrl}
              bubbles={bubbles.filter(
                (bubble) => bubble.blueprint_id === selectedBlueprint.id
              )}
              setBubbles={setBubbles}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ShowAllLocationsDialog
