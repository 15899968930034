import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Button } from '../ui/button'
import i18n from 'src/i18n'
import { Field, Form, Formik } from 'formik'
import { Project } from 'src/lib/types'
import { Label } from '../ui/label'
import { Textarea } from '../ui/textarea'
import FormErrorMessage from '../Forms/FormErrorMessage'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import * as Yup from 'yup'
import projectsService from 'src/services/Projects/projects'
import { toast } from 'react-toastify'
import { displayResponseErrorMessage } from 'src/lib/utils'

interface Props {
  project: Project
  setProject: Dispatch<SetStateAction<Project | null>>
}

interface FormValues {
  description: string
  info_section: string
}

const validationSchema = () =>
  Yup.object().shape({
    description: Yup.string().max(
      500,
      i18n.t('fieldValidation.maxCharacters', { maxLength: 500 })
    ),
    info_section: Yup.string().max(
      5000,
      i18n.t('fieldValidation.maxCharacters', { maxLength: 5000 })
    ),
  })

const ModifyProjectDialog = ({ project, setProject }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleSubmit = async (values: FormValues) => {
    try {
      const res = await projectsService.updateProjectById(
        {
          description: values.description,
          info_section: values.info_section,
        },
        project.id
      )

      if (res.status === 200) {
        // show success message
        const updatedProject = res.data as Project
        setProject(updatedProject)
        toast.success(i18n.t('successGeneric'))
        setDialogOpen(false)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger
        asChild
        data-testid="modify-project-infosection-dialog-trigger"
      >
        <Button>{i18n.t('modifyProject')}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{i18n.t('modifyProject')}</DialogTitle>
        </DialogHeader>
        <Formik
          initialValues={{
            description: project.description ?? '',
            info_section: project.info_section ?? '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col my-4 gap-3 p-4 rounded-md">
              <Label htmlFor="description">{i18n.t('description')}</Label>
              <Field
                id="description"
                name="description"
                data-testid="project-description"
                placeholder={i18n.t('description')}
                as={Textarea}
              />
              <FormErrorMessage name="description" />

              <Label htmlFor="info_section">{i18n.t('infosection')}</Label>
              <Field
                id="info_section"
                name="info_section"
                data-testid="project-info_section"
                placeholder={i18n.t('infoSectionPlaceholder')}
                as={Textarea}
              />
              <FormErrorMessage name="info_section" />

              <PendingSubmitButton
                buttonText={i18n.t('save')}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default ModifyProjectDialog
