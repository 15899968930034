import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import { useAppContext } from 'src/context/AppProvider'
import usersService from 'src/services/Users/users'
import ErrorPage from 'src/views/ErrorPage/ErrorPage'

const AdminPanelLayout = () => {
  const { initializeCurrentUser } = useAppContext()
  const [isVerifyingAccess, setIsVerifyingAccess] = useState<boolean>(false)
  const [accessVerified, setAccessVerified] = useState<boolean>(false)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setAccessVerified(false)
        setIsVerifyingAccess(true)
        const response = await usersService.verifyCrmAccess()
        if (response.status === 200) {
          await initializeCurrentUser()
          setAccessVerified(true)
        }
      } catch (error) {
        console.error('Access denied')
      } finally {
        setIsVerifyingAccess(false)
      }
    }
    fetchUser()
  }, [])

  if (isVerifyingAccess) {
    return <SmallLoadingCircleOnly />
  } else if (!isVerifyingAccess && !accessVerified) {
    return <ErrorPage />
  }

  return <Outlet />
}

export default AdminPanelLayout
