import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import FormErrorMessage from './FormErrorMessage'
import { Input } from '../ui/input'
import { Label } from '../ui/label'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import i18n from 'src/i18n'
import { passwordValidationYup } from 'src/lib/validationSchemas'
import { toast } from 'react-toastify'
import changePasswordService from 'src/services/Auth/change-password'
import { useNavigate } from 'react-router'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card'

interface ChangePasswordFormValues {
  newPassword: string
  confirmPassword: string
}

const initialValues: ChangePasswordFormValues = {
  newPassword: '',
  confirmPassword: '',
}

const validationSchema = () =>
  Yup.object({
    newPassword: passwordValidationYup(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), undefined], i18n.t('passwordsDontMatch'))
      .required(i18n.t('fieldValidation.required')),
  })

interface Props {
  token: string
}

const ChangePasswordForm = ({ token }: Props) => {
  const navigate = useNavigate()

  const handleSubmit = async (values: ChangePasswordFormValues) => {
    try {
      const response = await changePasswordService.changePassword({
        newPassword: values.newPassword,
        token,
      })

      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        navigate('/')
      } else {
        toast.error(i18n.t('errorGeneric'))
      }
    } catch (error) {
      console.error(error)
      toast.error(i18n.t('errorGeneric'))
    }
  }

  return (
    <Card data-testid="set-new-password-card">
      <CardHeader>
        <CardTitle>{i18n.t('setNewPassword')}</CardTitle>
      </CardHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <CardContent className="flex flex-col gap-3">
              <Label htmlFor="newPassword">{i18n.t('newPassword')}</Label>
              <Field
                type="password"
                id="newPassword"
                name="newPassword"
                placeholder={i18n.t('newPassword')}
                as={Input}
              />
              <FormErrorMessage name="newPassword" />

              <Label htmlFor="confirmPassword">
                {i18n.t('confirmPassword')}
              </Label>
              <Field
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder={i18n.t('confirmPassword')}
                as={Input}
              />
              <FormErrorMessage name="confirmPassword" />
            </CardContent>
            <CardFooter className="border-t px-6 py-4">
              <PendingSubmitButton
                buttonText={i18n.t('confirm')}
                isSubmitting={isSubmitting}
              />
            </CardFooter>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

export default ChangePasswordForm
