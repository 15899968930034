import React, { Dispatch, SetStateAction } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import { ForeignUserData } from 'src/lib/types'
import i18n from 'src/i18n'
import { Button } from '../ui/button'
import { toast } from 'react-toastify'
import { useParams } from 'react-router'
import {
  currentUserDataIsDefined,
  displayResponseErrorMessage,
  getUserCustomershipRoleAsText,
  hasCustomershipAdminRights,
} from 'src/lib/utils'
import { useConfirmaDialogStableTarget } from 'src/components/ui/confirmDialog'
import customershipService from 'src/services/Customerships/customershipService'
import SingleUserInfoDialog from './SingleUserInfoDialog'
import { useAppContext } from 'src/context/AppProvider'

interface Props {
  users: ForeignUserData[]
  setUsers: Dispatch<SetStateAction<ForeignUserData[]>>
}

const CustomershipUsersTable = ({ users, setUsers }: Props) => {
  const { customershipId } = useParams()
  const confirmDeletion = useConfirmaDialogStableTarget()

  const { currentUser } = useAppContext()

  const handleDeleteUserFromCustomership = async (userToDeleteId: string) => {
    const confirmed = await confirmDeletion({
      title: i18n.t('confirmDelete'),
    })
    if (!confirmed) {
      return
    }
    if (!customershipId) {
      return
    }
    try {
      const response = await customershipService.deleteUserFromCustomership(
        userToDeleteId,
        customershipId
      )
      if (response.status === 200) {
        setUsers((oldUsers) => {
          return oldUsers.filter((u) => u.userData.id !== userToDeleteId)
        })
        toast.success(i18n.t('successGeneric'))
      }
    } catch (error: any) {
      // Extract and display the error message from the response
      displayResponseErrorMessage(error)
    }
  }

  if (!customershipId || !currentUserDataIsDefined(currentUser)) {
    return <div>Loading project users</div>
  }
  const shouldRenderDeleteButtonToUserRow = (user: ForeignUserData) => {
    return (
      currentUser.userData.email !== user.userData.email &&
      hasCustomershipAdminRights(currentUser)
    )
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">{i18n.t('email')}</TableHead>
          <TableHead>{i18n.t('phone')}</TableHead>
          <TableHead>{i18n.t('userLevel')}</TableHead>
          <TableHead>{i18n.t('manage')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {users.map((user) => (
          <TableRow
            key={user.userData.email}
            data-testid={`users-table-row-${user.userData.email}`}
          >
            <TableCell className="font-medium">
              <SingleUserInfoDialog email={user.userData.email} />
            </TableCell>
            <TableCell>{user.userData.phone}</TableCell>
            <TableCell>{getUserCustomershipRoleAsText(user)}</TableCell>
            <TableCell>
              {shouldRenderDeleteButtonToUserRow(user) && (
                <Button
                  data-testid={`delete-user-from-customership-button-${user.userData.email}`}
                  variant={'destructive'}
                  onClick={() =>
                    handleDeleteUserFromCustomership(user.userData.id)
                  }
                >
                  {i18n.t('delete')}
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default CustomershipUsersTable
