import { Edit2, Plus, Users2 } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import ProjectCard from 'src/components/Projects/ProjectCard'
import { Button } from 'src/components/ui/button'
import { Card } from 'src/components/ui/card'
import { useAppContext } from 'src/context/AppProvider'
import i18n from 'src/i18n'
import { Customership, Project, UserCustomershipRoles } from 'src/lib/types'
import { currentUserDataIsDefined } from 'src/lib/utils'
import customershipService from 'src/services/Customerships/customershipService'

const SingleCustomershipView = () => {
  const { customershipId } = useParams()
  const { t } = i18n
  const { currentUser } = useAppContext()
  const [customership, setCustomership] = useState<Customership>()
  const [projects, setProjects] = useState<Project[]>([])

  useEffect(() => {
    const fetchCustomership = async () => {
      try {
        if (customershipId) {
          const responseCustomership =
            await customershipService.getCustomershipById(customershipId)
          if (responseCustomership.status === 200) {
            const resultCustomership = responseCustomership.data as Customership
            setCustomership(resultCustomership)
          }
          const responseProjects =
            await customershipService.getProjectsByCustomershipId(
              customershipId
            )
          if (responseProjects.status === 200) {
            const resultProjects = responseProjects.data as Project[]
            setProjects(resultProjects)
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchCustomership()
  }, [])

  if (
    !customershipId ||
    !customership ||
    !currentUserDataIsDefined(currentUser)
  ) {
    return <div>No customership or current user found</div>
  }

  return (
    <div className="mx-2 my-4">
      <div className="my-3">
        <div className="mb-2">
          <h2>
            {t('manageCustomership')} {customership.company_name} (
            {customership.business_id})
          </h2>
          <Card className="flex flex-row flex-wrap justify-evenly gap-2 my-4">
            <Link to={t('paths.inviteUsers')}>
              <Button
                variant={'secondary'}
                data-testid="invite-users-to-customership-link-button"
                className={'w-full'}
              >
                <Plus /> {t('invitation.inviteUsers')}
              </Button>
            </Link>

            <Link to={t('paths.customershipParticipants')}>
              <Button
                variant={'secondary'}
                data-testid="customership-participants-link-button"
                className={'w-full'}
              >
                <Users2 /> {t('customershipParticipants')}
              </Button>
            </Link>
            {currentUser.customership_role === UserCustomershipRoles.ADMIN && (
              <Link to={t('paths.modifyCustomership')}>
                <Button
                  variant={'secondary'}
                  data-testid="modify-customership-link-button"
                  className={'w-full'}
                >
                  <Edit2 /> {t('modifyCustomership')}
                </Button>
              </Link>
            )}
          </Card>
        </div>
        <h2>{t('projectsOfThisCustomership')}</h2>
        <div className="my-2">
          {projects && projects.length > 0 ? (
            projects.map((project) => (
              <div key={project.id} className="my-2">
                <ProjectCard project={project} />
              </div>
            ))
          ) : (
            <div>
              <p>{t('noProjectsFound')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SingleCustomershipView
