import { useEffect, useState } from 'react'
import CreateOrUpdateCustomershipForm from '../../components/Forms/CreateOrUpdateCustomershipForm'
import { Customership } from 'src/lib/types'
import { useParams } from 'react-router'
import customershipService from 'src/services/Customerships/customershipService'

const ModifyCustomershipView = () => {
  const { customershipId } = useParams()
  const [customership, setCustomership] = useState<Customership | null>(null)

  useEffect(() => {
    const fetchCustomership = async () => {
      try {
        if (customershipId) {
          const responseCustomership =
            await customershipService.getCustomershipById(customershipId)
          if (responseCustomership.status === 200) {
            const resultCustomership = responseCustomership.data as Customership
            setCustomership(resultCustomership)
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchCustomership()
  }, [])

  if (!customership) {
    return null
  }

  return (
    <div className="my-2">
      <CreateOrUpdateCustomershipForm customership={customership} />
    </div>
  )
}

export default ModifyCustomershipView
