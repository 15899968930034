import axiosInstance from 'src/axiosConfig'
import { Invitation } from 'src/lib/types'

const getPendingInvitations = async (): Promise<Invitation[]> => {
  try {
    const url = '/api/user/pending-invitations'
    const response = await axiosInstance.get(url)
    return response.data.pending_invitations as Invitation[]
  } catch (error) {
    console.error('Error fetching pending invitations:', error)
    return []
  }
}

const updateUserInvitations = async (): Promise<boolean> => {
  try {
    await axiosInstance.put('/api/user/update-invitations')
    return true
  } catch (error) {
    console.error('Error updating invitations:', error)
    return false
  }
}

const acceptInvitation = async (invitationId: string): Promise<boolean> => {
  try {
    await axiosInstance.put(`/api/invitations/${invitationId}/accept`)
    return true
  } catch (error) {
    console.error('Error accepting invitation:', error)
    return false
  }
}

const declineInvitation = async (invitationId: string): Promise<boolean> => {
  try {
    await axiosInstance.put(`/api/invitations/${invitationId}/decline`)
    return true
  } catch (error) {
    console.error('Error declining invitation:', error)
    return false
  }
}

export default {
  getPendingInvitations,
  updateUserInvitations,
  acceptInvitation,
  declineInvitation,
}
