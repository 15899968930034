import axiosInstance from 'src/axiosConfig'
import { CustomershipInvitation } from 'src/lib/types'
import qs from 'qs'
import { AxiosResponse } from 'axios'

const baseUrlCustomershipInvitation = '/api/customerships/invite-users'

interface InvitationData {
  emails: string[]
}

const getPendingInvitations = async (): Promise<CustomershipInvitation[]> => {
  try {
    const url = '/api/user/pending-customership-invitations'
    const response = await axiosInstance.get(url)
    return response.data.pending_invitations as CustomershipInvitation[]
  } catch (error) {
    console.error('Error fetching pending invitations:', error)
    return []
  }
}

const updateUserInvitations = async (): Promise<boolean> => {
  try {
    await axiosInstance.put('/api/user/update-customership-invitations')
    return true
  } catch (error) {
    console.error('Error updating invitations:', error)
    return false
  }
}

const acceptInvitation = async (invitationId: string): Promise<boolean> => {
  try {
    await axiosInstance.put(
      `/api/customerships/invitations/${invitationId}/accept`
    )
    return true
  } catch (error) {
    console.error('Error accepting invitation:', error)
    return false
  }
}

const declineInvitation = async (invitationId: string): Promise<boolean> => {
  try {
    await axiosInstance.put(
      `/api/customerships/invitations/${invitationId}/decline`
    )
    return true
  } catch (error) {
    console.error('Error declining invitation:', error)
    return false
  }
}

const inviteUsersToCustomership = async (
  invitationData: InvitationData,
  customership_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrlCustomershipInvitation}/${customership_id}`,
    qs.stringify(invitationData, { arrayFormat: 'repeat' })
  )
  return response
}

const fetchCustomershipInvitationStatuses = async (invitationData: {
  status: string
  customershipId: string
}): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `/api/customerships/${invitationData.customershipId}/invitations/invitation-status`,
    {
      params: {
        status: invitationData.status,
      },
    }
  )
  return response
}

export default {
  getPendingInvitations,
  updateUserInvitations,
  acceptInvitation,
  declineInvitation,
  inviteUsersToCustomership,
  fetchCustomershipInvitationStatuses,
}
