import React, { useEffect, useState } from 'react'
import EndProjectForm from 'src/components/Projects/EndProjectForm'
import ErrorPage from '../ErrorPage/ErrorPage'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import projectsService from 'src/services/Projects/projects'
import { useParams } from 'react-router'
import { Project, UserProjectRoles } from 'src/lib/types'
import { isProjectActive } from 'src/lib/utils'
import i18n from 'src/i18n'
import { useAppContext } from 'src/context/AppProvider'

const EndProjectView = () => {
  const { projectId } = useParams()
  const [project, setProject] = useState<Project | null>(null)

  const { currentUser, currentProject } = useAppContext()

  useEffect(() => {
    const fetchProject = async () => {
      if (projectId) {
        try {
          const response = await projectsService.getProjectById(projectId)
          if (response.status === 200) {
            setProject(response.data)
          }
        } catch (error) {
          console.error(error)
        }
      }
    }

    fetchProject()
  }, [])

  if (!project) {
    return <SmallLoadingCircleOnly />
  }
  if (!currentProject) {
    return <ErrorPage />
  }

  const projectIsActive = isProjectActive(project)

  return (
    <div className="m-2 min-h-screen">
      <h1 className="mb-5">{i18n.t('endProject')}</h1>
      {projectIsActive &&
        currentUser.project_role === UserProjectRoles.ADMIN && (
          <EndProjectForm project={project} />
        )}
    </div>
  )
}

export default EndProjectView
