import { AxiosResponse } from 'axios'
import axiosInstance from 'src/axiosConfig'

const baseUrl = '/api/customerships'

interface CreateCustomershipData {
  company_name: string
  business_id: string
  einvoice_address: string
  operator_id: string
  postal_address: string
  email: string
  phone: string
  contact_person: string
  reference_number?: string
}

interface SearchProps {
  search: string
  results_per_page: number
  page: number
}

type UpdateCustomershipData = Omit<CreateCustomershipData, 'business_id'>

const createNewCustomership = async (
  customershipData: CreateCustomershipData
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrl}/create-new`,
    customershipData
  )
  return response
}

const modifyCustomershipById = async (
  customershipData: UpdateCustomershipData,
  customershipId: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.put(
    `${baseUrl}/modify/${customershipId}`,
    customershipData
  )
  return response
}

const getUsersCustomerships = async (): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(baseUrl + '/get-user-customerships')
  return response
}

const searchUsersCustomerships = async (
  searchData: SearchProps
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    baseUrl + '/search-user-customerships',
    searchData
  )
  return response
}

const getCustomershipById = async (id: string): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(`${baseUrl}/${id}`)
  return response
}

const getProjectsByCustomershipId = async (
  id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(`${baseUrl}/${id}/projects`)
  return response
}

const deleteCurrentUserFromCustomership = async (
  customership_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.delete(
    `${baseUrl}/delete-current-user-from-customership/${customership_id}`
  )
  return response
}

const deleteUserFromCustomership = async (
  userToDeleteId: string,
  customership_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.delete(
    `${baseUrl}/delete-user-from-customership/${customership_id}`,
    { data: { user_id: userToDeleteId } }
  )
  return response
}

export default {
  createNewCustomership,
  searchUsersCustomerships,
  getCustomershipById,
  getProjectsByCustomershipId,
  deleteCurrentUserFromCustomership,
  deleteUserFromCustomership,
  modifyCustomershipById,
  getUsersCustomerships,
}
