import React, { useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import BreadcrumbsSingleCustomership from 'src/components/Customerships/BreadcrumbsSingleCustomership'
import ErrorPage from 'src/views/ErrorPage/ErrorPage'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import { useAppContext } from 'src/context/AppProvider'
import { Customership } from 'src/lib/types'
import { currentUserDataWithCustomershipRoleIsDefined } from 'src/lib/utils'

const SingleCustomershipLayout = () => {
  const { customershipId } = useParams<{ customershipId: string }>()
  const [loading, setLoading] = useState<boolean>(true)
  const [userDataValidated, setUserDataValidated] = useState<boolean | null>(
    null
  )

  const {
    currentCustomership,
    currentUser,
    initializeCurrentCustomership,
    setCurrentCustomership,
    initializeCurrentUser,
  } = useAppContext()

  useEffect(() => {
    const initialize = async () => {
      setLoading(true)
      setUserDataValidated(null) // Reset validation state on each load
      try {
        if (customershipId) {
          // Initialize customership and user sequentially
          await initializeCurrentCustomership(customershipId)
          await initializeCurrentUser(undefined, customershipId)
        } else {
          setCurrentCustomership({} as Customership)
          setUserDataValidated(false) // No customership to validate, mark as invalid
        }
      } catch (error) {
        setUserDataValidated(false)
      } finally {
        setLoading(false)
      }
    }

    initialize()
  }, [customershipId])

  useEffect(() => {
    if (currentUserDataWithCustomershipRoleIsDefined(currentUser)) {
      setUserDataValidated(true)
    } else if (
      currentUser.customership_role === null ||
      currentUser.customership_role === undefined
    ) {
      // If customership role is null or undefined, mark validation as failed
      setUserDataValidated(false)
    }
  }, [currentUser])

  if (loading) {
    return <SmallLoadingCircleOnly />
  }

  if (userDataValidated === false) {
    return <ErrorPage />
  }

  return (
    <div>
      <div className="mb-4">
        <BreadcrumbsSingleCustomership customership={currentCustomership} />
      </div>
      <Outlet />
    </div>
  )
}

export default SingleCustomershipLayout
