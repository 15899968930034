import React, { ChangeEvent, FormEvent, useState } from 'react'
import { useNavigate } from 'react-router'
import { Button } from '../ui/button'
import { Label } from '../ui/label'
import { Input } from '../ui/input'
import { toast } from 'react-toastify'
import i18n from 'src/i18n'
import { v4 as uuidv4 } from 'uuid'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card'
import { Separator } from '../ui/separator'
import customershipInvitationService from 'src/services/CustomershipInvitations/customershipInvitationService'
import { InvitationResponse } from 'src/lib/types'

interface Props {
  customershipId: string
}

interface EmailItem {
  id: string
  email: string
}

const InviteUserToCustomershipForm = ({ customershipId }: Props) => {
  const [emailList, setEmailList] = useState<EmailItem[]>([])
  const [emailInput, setEmails] = useState<string>('')

  const { t } = i18n
  const navigate = useNavigate()

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const addSingleEmails = () => {
    // Split the input into individual emails using commas or semicolons
    const emails = emailInput
      .split(/[\s,;]+/)
      .filter(Boolean)
      .filter((email) => email.match(emailPattern))

    if (emails.length === 0) {
      toast.error(i18n.t('emailValidation.invalidFormat'))
      return
    }

    // Map each email into an `EmailItem` object and add only unique ones to the list
    const newEmailList = emails
      .filter((email) => !emailList.some((item) => item.email === email))
      .map((email) => ({ id: uuidv4(), email }))

    // Combine existing and new emails into the email list state
    setEmailList([...emailList, ...newEmailList])

    // Clear the single input field
    setEmails('')
  }

  // Remove an email from the list by ID
  const removeEmail = (id: string) => {
    setEmailList(emailList.filter((item) => item.id !== id))
  }

  const handleInviteUsers = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const emails = emailList.map((item) => item.email)

    if (!emails.length || !customershipId) {
      toast.error(i18n.t('invitation.noValidEmailsProvided'))
      return
    }

    try {
      const response =
        await customershipInvitationService.inviteUsersToCustomership(
          {
            emails: emails,
          },
          customershipId
        )
      if (response.status === 200) {
        const responseData = response.data as InvitationResponse
        if (responseData.success) {
          toast.success(i18n.t('invitation.responses.invitationSent'))
          setEmailList([])
          navigate(t('paths.customershipPortal'))
          if (responseData.one_or_more_email_failed) {
            toast.info(
              i18n.t('invitation.responses.oneOrMoreEmailSendingFailed')
            )
          }
        } else {
          throw new Error('Error')
        }
      }
    } catch (error) {
      toast.error(i18n.t('invitation.responses.unexpectedError'))
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{i18n.t('inviteUsersToCustomership')}</CardTitle>
      </CardHeader>
      <CardContent className="sm:max-w-xl">
        <p className="text-sm mb-4">
          {i18n.t('invitation.customershipInvitationInstructions')}
        </p>
        <form className="flex flex-col gap-3" onSubmit={handleInviteUsers}>
          <Label htmlFor="singleEmail">
            {i18n.t('invitation.tableHeaders.addinvitees')}
          </Label>
          <div className="flex gap-2">
            <Input
              id="singleEmail"
              type="email"
              name="email"
              value={emailInput}
              placeholder={i18n.t('invitation.placeHolders.addInvitees')}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmails(e.target.value)
              }
            />
            <Button
              type="button"
              onClick={addSingleEmails}
              data-testid="add-single-email-button"
            >
              {i18n.t('invitation.buttons.addSingleEmail')}
            </Button>
          </div>
          {/* Scrollable List of Added Emails */}
          <div className="bg-white rounded-md p-2 h-40 overflow-y-auto border">
            <ul>
              {emailList.map((item) => (
                <li
                  key={item.id}
                  className="flex justify-between p-2 border-b last:border-b-0"
                >
                  <span>{item.email}</span>
                  <Button
                    type="button"
                    onClick={() => removeEmail(item.id)}
                    data-testid={`remove-email-button-${item.email}`}
                  >
                    &times;
                  </Button>
                </li>
              ))}
            </ul>
          </div>
          <Separator />
          <CardFooter>
            <Button type="submit" data-testid="send-invitation-emails-button">
              {i18n.t('invitation.buttons.send')}
            </Button>
          </CardFooter>
        </form>
      </CardContent>
    </Card>
  )
}

export default InviteUserToCustomershipForm
