import React from 'react'
import { Sheet, SheetContent, SheetTrigger } from '../ui/sheet'
import { Button } from '../ui/button'
import {
  Contact,
  History,
  Home,
  Image,
  Package,
  PanelLeft,
  Settings,
  Users2,
} from 'lucide-react'
import { Link } from 'react-router-dom'
import i18n from 'src/i18n'
import { linkStyling } from 'src/constants'
import { useAppContext } from 'src/context/AppProvider'
import { UserProjectRoles } from 'src/lib/types'

interface Props {
  projectId: string
}

const SidenavSheet = ({ projectId }: Props) => {
  const { currentUser } = useAppContext()

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button size="icon" variant="outline" className="sm:hidden">
          <PanelLeft className="h-5 w-5" />
          <span className="sr-only">Toggle Menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="sm:max-w-xs">
        <nav className="grid gap-6 text-lg font-medium">
          <Link to={i18n.t('paths.mainscreen')} className={linkStyling}>
            <Home className="h-5 w-5" />
            {i18n.t('mainscreen')}
          </Link>
          <Link
            to={i18n.t('paths.projects') + `/${projectId}/`}
            className={linkStyling}
          >
            <Image className="h-5 w-5" />
            {i18n.t('project')}
          </Link>
          <Link
            to={
              i18n.t('paths.projects') +
              `/${projectId}/` +
              i18n.t('paths.inventarion')
            }
            className={linkStyling}
          >
            <Package className="h-5 w-5" />
            {i18n.t('inventarion')}
          </Link>
          <Link
            to={
              i18n.t('paths.projects') +
              `/${projectId}/` +
              i18n.t('paths.history')
            }
            className={linkStyling}
          >
            <History className="h-5 w-5" />
            {i18n.t('projectHistory')}
          </Link>
          <Link
            to={
              i18n.t('paths.projects') +
              `/${projectId}/` +
              i18n.t('paths.infosection')
            }
            className={linkStyling}
          >
            <Contact className="h-5 w-5" />
            {i18n.t('projectInformation')}
          </Link>
          <Link
            to={
              i18n.t('paths.projects') +
              `/${projectId}/` +
              i18n.t('paths.projectParticipants')
            }
            className={linkStyling}
          >
            <Users2 className="h-5 w-5" />
            {i18n.t('projectParticipants')}
          </Link>
          {currentUser.project_role === UserProjectRoles.ADMIN && (
            <Link
              to={
                i18n.t('paths.projects') +
                `/${projectId}/` +
                i18n.t('paths.settings')
              }
              className={linkStyling}
            >
              <Settings className="h-5 w-5" />
              {i18n.t('projectSettings')}
            </Link>
          )}
        </nav>
      </SheetContent>
    </Sheet>
  )
}

export default SidenavSheet
