import axiosInstance from 'src/axiosConfig'
import { InvitationStatusResponse } from 'src/lib/types'

const baseUrl = '/api/project/invitation-status'

interface InvitationData {
  project_id: string
  status: string
}

export async function fetchInvitationStatuses(
  invitationData: InvitationData
): Promise<InvitationStatusResponse> {
  try {
    // Pass the project ID as a query parameter using the `params` option
    const response = await axiosInstance.get(baseUrl, {
      params: {
        project_id: invitationData.project_id,
        status: invitationData.status,
      },
    })

    // Return the response data directly
    return { invitations: response.data.invitations }
  } catch (error) {
    // Log the error and return a standardized error message
    console.error('Error fetching invitation statuses:', error)
    return { invitations: [], error: 'Failed to fetch invitation statuses.' }
  }
}
