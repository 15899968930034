import { useEffect } from 'react'
import { Outlet, useParams } from 'react-router'
import { useAppContext } from './AppProvider'

const UserProvider = () => {
  const { projectId, customershipId } = useParams<{
    projectId?: string
    customershipId?: string
  }>()

  const { initializeCurrentUser, currentUser } = useAppContext()

  useEffect(() => {
    const fetchCurrentUser = async () => {
      await initializeCurrentUser(projectId, customershipId)
    }
    fetchCurrentUser()
  }, [projectId, customershipId])

  console.log('Current User: ', currentUser)

  return <Outlet />
}
export default UserProvider
