import React, { useEffect, useState } from 'react'
import { BsCircleFill } from 'react-icons/bs'
import { Link, useSearchParams } from 'react-router-dom'
import CustomershipInvitationsTable from 'src/components/CustomershipInvitations/CustomershipInvitationsTable'
import SearchCustomerships from 'src/components/Customerships/SearchCustomerships'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import Pagination from 'src/components/Pagination'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card'
import { Separator } from 'src/components/ui/separator'
import { iconAndTextStyling } from 'src/constants'
import i18n from 'src/i18n'
import { Customership } from 'src/lib/types'
import customershipService from 'src/services/Customerships/customershipService'

const CustomershipPortalMainView = () => {
  const [customerships, setCustomerships] = useState<Customership[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const [totalResults, setTotalResults] = useState<number>(0)
  const { t } = i18n
  const resultsPerPage = 4

  const fetchCustomerships = async () => {
    try {
      setLoading(true)
      const page = searchParams.get('page')
      const searchQuery = searchParams.get('search') ?? ''
      const response = await customershipService.searchUsersCustomerships({
        search: searchQuery,
        page: page ? parseInt(page) : 1,
        results_per_page: resultsPerPage,
      })
      if (response.status === 200) {
        const result = response.data.customerships as Customership[]
        setCustomerships(result)
        setTotalResults(response.data.totalResults)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchCustomerships()
  }, [searchParams])

  return (
    <div className="mx-2 my-4" data-testid="customership-portal-main-view">
      <div className="flex flex-row flex-wrap justify-between gap-2">
        <h1>{t('yourCustomership')}</h1>
      </div>
      <div className="my-3">
        <SearchCustomerships />
      </div>
      <div className="my-4">
        {loading ? (
          <SmallLoadingCircleOnly />
        ) : customerships && customerships.length > 0 ? (
          <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {customerships.map((customership) => (
                <Card
                  key={customership.id}
                  data-testid={`customership-card-${customership.business_id}`}
                >
                  <Link
                    to={
                      t('paths.ownCustomerships') +
                      '/' +
                      customership.id +
                      '/' +
                      t('paths.manageCustomership')
                    }
                  >
                    <CardHeader>
                      <CardTitle
                        data-testid={`customership-company-name-${customership.business_id}`}
                      >
                        {customership.company_name}
                      </CardTitle>
                      <CardDescription
                        data-testid={`customership-businessId-${customership.business_id}`}
                      >
                        {customership.business_id}
                      </CardDescription>
                      <CardDescription className={iconAndTextStyling}>
                        {customership.is_active ? (
                          <>
                            <BsCircleFill className="text-green-400" />{' '}
                            {t('activityStates.active')}
                          </>
                        ) : (
                          <>
                            <BsCircleFill className="text-red-400" />{' '}
                            {t('activityStates.notActive')}
                          </>
                        )}
                      </CardDescription>
                    </CardHeader>
                    <Separator className="mb-2" />
                    <CardContent>
                      <h3 className="font-bold">{t('customerships.info')}</h3>
                      <Separator />
                      <p
                        data-testid={`customership-postal-address-${customership.business_id}`}
                      >
                        {t('postalAddress')} {customership.postal_address}
                      </p>
                      <p
                        data-testid={`customership-einvoice-address-${customership.business_id}`}
                      >
                        {t('eInvoiceAddress')} {customership.einvoice_address}
                      </p>
                      <p
                        data-testid={`customership-operatorId-${customership.business_id}`}
                      >
                        {t('operatorId')} {customership.operator_id}
                      </p>
                      <p
                        data-testid={`customership-reference-number-${customership.business_id}`}
                      >
                        {t('referenceNumber')} {customership.reference_number}
                      </p>
                      <h3 className="mt-2 font-bold">{t('contactPerson')}</h3>
                      <Separator />
                      <p
                        data-testid={`customership-contact-person-${customership.business_id}`}
                      >
                        {customership.contact_person}
                      </p>
                      <h3 className="mt-2 font-bold">
                        {t('customerships.contactInformation')}
                      </h3>
                      <Separator />
                      <p
                        data-testid={`customership-email-${customership.business_id}`}
                      >
                        {customership.email}
                      </p>
                      <p
                        data-testid={`customership-phone-${customership.business_id}`}
                      >
                        {customership.phone}
                      </p>
                    </CardContent>
                  </Link>
                </Card>
              ))}
            </div>
            <div className="text-center my-2">
              <Pagination
                totalPages={Math.ceil(totalResults / resultsPerPage)}
              />
            </div>
          </div>
        ) : (
          <div>
            <h2>{t('noCustomershipsFound')}</h2>
          </div>
        )}
      </div>
      <div className="my-4">
        <CustomershipInvitationsTable setCustomerships={setCustomerships} />
      </div>
    </div>
  )
}

export default CustomershipPortalMainView
