import { AxiosResponse } from 'axios'
import axiosInstance from 'src/axiosConfig'
import { Project } from 'src/lib/types'

const baseUrl = '/api/projects'

interface AddProjectData {
  name: string
  description?: string
  customership_id: string
  price: number
  max_blueprints: number
}

interface ModifyProjectData {
  description?: string
  info_section?: string
}

const addNewProject = async (
  projectData: AddProjectData
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(`${baseUrl}/add-new`, projectData)
  return response
}

interface SearchProjectProps {
  search: string
  results_per_page: number
  page: number
}

const searchUsersProjects = async (
  searchData: SearchProjectProps
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    baseUrl + '/search-user-projects',
    searchData
  )
  return response
}

const getProjectById = async (id: string): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(`${baseUrl}/${id}`)
  return response
}

const updateProjectById = async (
  projectData: ModifyProjectData,
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.put(
    `${baseUrl}/${project_id}`,
    projectData
  )
  return response
}

interface EndProjectData {
  project_end_date: Date
}

const endProjectById = async (
  data: EndProjectData,
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.put(
    `${baseUrl}/${project_id}/end-project`,
    data
  )
  return response
}

const cancelProjectTerminationByProjectId = async (
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.put(
    `${baseUrl}/${project_id}/cancel-termination`
  )
  return response
}

const deleteCurrentUserFromProject = async (
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.delete(
    `${baseUrl}/delete-current-user-from-project/${project_id}`
  )
  return response
}

const deleteUserFromProject = async (
  userToDeleteId: string,
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.delete(
    `${baseUrl}/delete-user-from-project/${project_id}`,
    { data: { user_id: userToDeleteId } }
  )
  return response
}

const getAllProjects = async (): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(`/api/crm/all-projects`)
  return response
}

const getUserProjects = async () => {
  const request = await axiosInstance.get(baseUrl + '/get-user-projects')
  return request.data as Project[]
}

export default {
  addNewProject,
  getProjectById,
  searchUsersProjects,
  updateProjectById,
  deleteCurrentUserFromProject,
  deleteUserFromProject,
  endProjectById,
  cancelProjectTerminationByProjectId,
  getAllProjects,
  getUserProjects,
}
