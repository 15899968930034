import { Link, Outlet } from 'react-router-dom'
import i18n from 'src/i18n'

const ProfileLayout = () => {
  return (
    <div className="flex min-h-screen w-full flex-col">
      <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10">
        <div className="mx-auto grid w-full max-w-6xl gap-2">
          <h1 className="text-3xl font-semibold">
            {i18n.t('profile.profile')}
          </h1>
        </div>
        <div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
          <nav
            className="grid gap-4 text-sm text-muted-foreground"
            x-chunk="dashboard-04-chunk-0"
          >
            <Link
              to=""
              className="font-semibold text-primary"
              data-testid="profile-sidenav-link-general"
            >
              {i18n.t('profile.general')}
            </Link>
            <Link
              to={i18n.t('paths.settings')}
              className="font-semibold text-primary"
              data-testid="profile-sidenav-link-settings"
            >
              {i18n.t('profile.settings')}
            </Link>
          </nav>
          <div className="grid gap-6">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  )
}

export default ProfileLayout
