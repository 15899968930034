import i18n from 'src/i18n'

export const errorMessageHandler = (message: string) => {
  switch (message) {
    case 'Last admin cannot delete themselves from project':
      return i18n.t('errorMessages.lastAdminCantLeaveProject')
    case 'Not enough permissions, admin required':
      return i18n.t('permissions.adminRequired')
    case 'Not enough permissions, moderator required':
      return i18n.t('permissions.moderatorRequired')
    case 'Not enough permissions, member required':
      return i18n.t('permissions.memberRequired')
    case 'Error: Project is not active':
      return i18n.t('errorMessages.projectNotActive')
    case 'Project end date cannot be before contract end date':
      return i18n.t('errorMessages.projectEndDateCannotBeBeforeContractEnd')
    case 'Project end date must be the last day of a month':
      return i18n.t('errorMessages.projectEndDateMustBeALastDayOfAMonth')
    case 'Project end date cannot be before the earliest termination date':
      return i18n.t('errorMessages.projectEndDateConsiderTerminationDuration')
    case 'Last admin cannot delete themselves from customership':
      return i18n.t('errorMessages.lastAdminCantLeaveCustomership')
    case 'User is the only admin in at least one project, deletion cannot be done.':
      return i18n.t('errorMessages.userDeletionFailedLastAdminInProject')
    case 'User is the only admin in at least one customership, deletion cannot be done.':
      return i18n.t('errorMessages.userDeletionFailedLastAdminInCustomership')
    default:
      return i18n.t('errorGeneric')
  }
}
