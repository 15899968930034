import { Link } from 'react-router-dom'
import { Button } from 'src/components/ui/button'
import i18n from 'src/i18n'

const ErrorPage = ({ message }: { message?: string }) => {
  const path =
    localStorage.getItem('IsLoggedIn') === 'true'
      ? i18n.t('paths.mainscreen')
      : '/'

  return (
    <div
      className="min-h-screen bg-gray-100 flex flex-col justify-center items-center"
      data-testid="generic-error-page"
    >
      <div className="max-w-md p-6 bg-white shadow-lg rounded-lg">
        <h1 className="text-3xl font-bold text-red-600 mb-4">
          {i18n.t('unexpectedErrorOccured')}
        </h1>
        <p className="text-gray-600 mb-4">{message ?? `${i18n.t('')}`}</p>
        <Button onClick={() => window.location.reload()}>
          {i18n.t('refreshPage')}
        </Button>
        <Button variant={'link'}>
          <Link to={path}> {i18n.t('moveToMainscreen')} </Link>
        </Button>
      </div>
    </div>
  )
}

export default ErrorPage
