import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog'
import { Button } from '../ui/button'
import { MapIcon } from 'lucide-react'
import { Blueprint, Bubble, DeletedItem, Item } from 'src/lib/types'
import bubblesService from 'src/services/Bubbles/bubbles'
import { toast } from 'react-toastify'
import i18n from 'src/i18n'
import SmallLoadingCircleOnly from '../Loading/SmallLoadingCircle'
import blueprintsService from 'src/services/Blueprints/blueprints'
import DisplaySingleBlueprintImage from '../Blueprints/DisplaySingleBlueprintImage'
import { useAppContext } from 'src/context/AppProvider'

interface Props {
  item: Item | DeletedItem
}

const ShowLocationOnMapDialog = ({ item }: Props) => {
  const [bubble, setBubble] = useState<Bubble | null>(null)
  const [blueprint, setBlueprint] = useState<Blueprint | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState<string>('')

  const { currentProject } = useAppContext()
  const projectId = currentProject.id

  const fetchBubbleData = async () => {
    setLoading(true)
    try {
      if (!projectId || !item.bubble_id) {
        return
      }
      const bubble = (await bubblesService.getBubbleById(
        item.bubble_id,
        projectId
      )) as Bubble
      const signedUrl = await blueprintsService.getSignedUrlByBlueprintId(
        bubble.blueprint_id,
        projectId
      )
      const blueprint = await blueprintsService.getBlueprintById(
        bubble.blueprint_id,
        projectId
      )
      if (bubble && blueprint && signedUrl) {
        setImageUrl(signedUrl)
        setBubble(bubble)
        setBlueprint(blueprint)
        setLoading(false)
      } else {
        throw new Error(i18n.t('errorFetchingBubbleData'))
      }
    } catch (error) {
      toast.error(i18n.t('errorFetchingBubbleData'))
      setLoading(false)
    }
  }

  return (
    <Dialog
      onOpenChange={async (open) => {
        if (open) {
          await fetchBubbleData()
        }
      }}
    >
      <DialogTrigger asChild>
        <Button variant={'secondary'}>
          <MapIcon />
        </Button>
      </DialogTrigger>
      <DialogContent>
        {loading && !bubble && !blueprint && (
          <SmallLoadingCircleOnly borderColor="border-blue-500" />
        )}
        {bubble && blueprint && (
          <div className="w-full">
            <h1>{bubble.title}</h1>
            <DisplaySingleBlueprintImage
              blueprint={blueprint}
              bubbles={[bubble]}
              imageSource={imageUrl}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ShowLocationOnMapDialog
