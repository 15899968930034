import { Input } from '../ui/input'
import i18n from 'src/i18n'
import { Button } from '../ui/button'
import { Search } from 'lucide-react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useState } from 'react'

const SearchCustomerships = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchQuery, setSearchQuery] = useState<string>(
    searchParams.get('search') || ''
  )

  const handleSearch = () => {
    searchParams.set('page', '1')
    if (searchQuery) {
      searchParams.set('search', searchQuery) // Set search query in URLSearchParams
    } else {
      searchParams.delete('search')
    }
    navigate({ pathname: location.pathname, search: searchParams.toString() })
  }

  return (
    <div className="flex flex-row gap-2 flex-grow flex-nowrap">
      <Input
        type="search"
        placeholder={i18n.t('searchForCustomership')}
        className="w-full rounded-lg"
        data-testid="search-customerships-input-searchbar"
        value={searchQuery} // Controlled input value
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Button onClick={handleSearch} data-testid="search-button-searchbar">
        <Search />
      </Button>
    </div>
  )
}

export default SearchCustomerships
